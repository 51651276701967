let analyticsFunctions = require('./analyticsFunctions.js');

/*global
    fireTag
*/

// The product Analytics class is now only being used on the vehicle spolight page in production. 
// In staging the Vehicle spotlight page has been refactored and no longer uses this class. When the staging is 
// pushed to prod this class be deleted and we will have fully migrated to global analytics in all environments. 

let config = {
    appId: analyticsFunctions.getAppId(),
    section: "Product",
    subsection: "Featured Technology",
    winView: analyticsFunctions.getViewPort(),
    site_size: analyticsFunctions.getSiteSize(),
};

export class ProductAnalytics {
    constructor(params) {
        if (!params)
            params = {};
        this.globals = params;
        this.pageLoadTag = params.pageLoadTag ? params.pageLoadTag : "29.1";
        this.onClickTag = params.onClickTag ? params.onClickTag : "29.2";
        this.modelName = params.modelName ? params.modelName : "";
        this.modelYear = params.modelYear ? params.modelYear : "";
        this.site_size = config.site_size ? config.site_size : "";
    }

    firePageLoad = function firePageLoad(params) {
        let section = params.section ? params.section : "";
        let subsection = params.subsection ? params.subsection : this.globals.subsection;

        if (typeof fireTag !== 'undefined') {
            let body = {
                //User Analytics
                "<user_name>": localStorage ? localStorage.getItem('username') : "USERNAME",
                "<dealer_code>": localStorage ? localStorage.getItem('dealer_code') : "DEALER_CODE",
                "<user_type>": localStorage ? localStorage.getItem('user_type') : "USER_TYPE",
                "<region>": localStorage ? localStorage.getItem('region_code') : "REGION",
                "<position_title>": localStorage ? localStorage.getItem("position_title") : "POSITION_TITLE",
                "<job_code>": localStorage ? localStorage.getItem("job_code") : "JOB_CODE",
                "<spin_id>": localStorage ? localStorage.getItem("spin_id") : "SPIN_ID",
                //Page Analytics
                "<app_id>": config.appId,
                "<viewport>": config.winView,
                "<model_name>": this.globals.modelName ? this.globals.modelName : "MODEL_NAME",
                "<model_year>": this.globals.modelYear ? this.globals.modelYear : "MODEL_YEAR",
                "<page>": this.globals.page ? this.globals.page : "PAGE",
                "<section>": section,
                "<site_size>": config.site_size,
                "<subsection>": subsection
            }
            let tag = params.tag ? params.tag : this.pageLoadTag;
            fireTag(tag, body);
        }
    }

    fireOnClick = function fireOnClick(params) {
        if (typeof fireTag !== 'undefined') {

            let body = {
                //User Analytics
                "<user_name>": localStorage ? localStorage.getItem('username') : "USERNAME",
                "<dealer_code>": localStorage ? localStorage.getItem('dealer_code') : "DEALER_CODE",
                "<user_type>": localStorage ? localStorage.getItem('user_type') : "USER_TYPE",
                "<region>": localStorage ? localStorage.getItem('region_code') : "REGION",
                "<position_title>": localStorage ? localStorage.getItem("position_title") : "POSITION_TITLE",
                "<job_code>": localStorage ? localStorage.getItem("job_code") : "JOB_CODE",
                "<spin_id>": localStorage ? localStorage.getItem("spin_id") : "SPIN_ID",
                //Page Analytics
                "<app_id>": config.appId,
                "<viewport>": config.winView,
                "<category>": params.category ? params.category : this.globals.category,
                "<container>": params.container ? params.container : this.globals.container,
                "<label>": params.label ? params.label : this.globals.label,
                "<model_name>": this.globals.modelName ? this.globals.modelName : "MODEL_NAME",
                "<model_year>": this.globals.modelYear ? this.globals.modelYear : "MODEL_YEAR",
                "<module>": params.module ? params.module : "MODULE",
                "<content_title>": params.contentTitle ? params.contentTitle : this.globals.contentTitle,
                "<page>": this.globals.page ? this.globals.page : params.page ? params.page : "PAGE",
                "<section>": params.section ? params.section : "",
                "<site_size>": config.site_size,
            }
            let tag = params.tag ? params.tag : this.onClickTag;
            fireTag(tag, body);
        }
    }

}

export class GlobalAnalytics {
    constructor() {
        this.pageLoadTag = "35.1";
        this.onClickTag = "35.2";
    }

    firePageLoad = function firePageLoad(params) {
        if (typeof fireTag !== 'undefined') {
            let positionTitle = localStorage ? localStorage.getItem("position_title") : "POSITION_TITLE";
            if (params.positionTitle) positionTitle = params.positionTitle;
            
            let body = {
                //User Analytics
                "<user_name>": localStorage ? localStorage.getItem('username') : "USERNAME",
                "<dealer_code>": localStorage ? localStorage.getItem('dealer_code') : "DEALER_CODE",
                "<user_type>": localStorage ? localStorage.getItem('user_type') : "USER_TYPE",
                "<region>": localStorage ? localStorage.getItem('region_code') : "REGION",
                "<position_title>": positionTitle,
                "<job_code>": localStorage ? localStorage.getItem("job_code") : "JOB_CODE",
                "<spin_id>": localStorage ? localStorage.getItem("spin_id") : "SPIN_ID",

                //Page Analytics
                "<app_id>": config.appId,
                "<site_size>": config.site_size,
                "<viewport>": config.winView,
                "<model_name>": params.modelName ? params.modelName : "<model_name> is undefined",
                "<model_year>": params.modelYear ? params.modelYear : "<model_year> is undefined",
                "<model_code>": params.modelCode ? params.modelCode : "<model_code> is undefined",
                "<trim>": params.trim ? params.trim : "<trim> is undefined",
                "<model_engine>": params.modelEngine ? params.modelEngine : "<model_engine> is undefined",
                "<model_cab>": params.modelCab ? params.modelCab : "<model_cab> is undefined",
                "<model_bed>": params.modelBed ? params.modelBed : "<model_bed> is undefined",
                "<model_drivetrain>": params.modelDrivetrain ? params.modelDrivetrain : "<model_drivetrain> is undefined",
                "<page>": params.page ? params.page : "<page> is undefined",
                "<section>": params.section ? params.section : "<section> is undefined",
                "<subsection>": params.subsection ? params.subsection : "<subsection> is undefined",
                "<technology_name>": params.technologyName ? params.technologyName : "<technologyName> is undefined",
                "<content_title>": params.contentTitle ? params.contentTitle : "<content_title> is undefined",
                "<media_title>": params.mediaTitle ? params.mediaTitle : "<media_title> is undefined",


                //Skills Specific
                "<category_topic>": params.categoryTopic ? params.categoryTopic : "<category_topic> is undefined",

                // Article Specific
                "<article_type>": params.articleType ? params.articleType : "<article_type> is undefined",

                // Search Specific
                "<description>": params.description ? params.description : "<description> is undefined",
                "<keyword>": params.keyword ? params.keyword : "<keyword> is undefined",
                "<number_of_search_results>": params.numberOfSearchResults ? params.numberOfSearchResults : "<number_of_search_results> is undefined",

                // Hybrid App
                "<platform>": window.ReactNativeWebView ? "App" : "Web",
                "<os_version>": (sessionStorage?.getItem('app_device_os') || localStorage?.getItem('app_device_os')) ?? "<os_version> is undefined",                
                "<app_version>": (sessionStorage?.getItem('app_version') || localStorage?.getItem('app_version')) ?? "<app_version> is undefined",
            }
            let tag = params.tag ? params.tag : this.pageLoadTag;
            fireTag(tag, body);
        }
    }

    fireOnClick = function fireOnClick(params) {
        if (typeof fireTag !== 'undefined') {
            let positionTitle = localStorage ? localStorage.getItem("position_title") : "POSITION_TITLE";
            if (params.positionTitle) positionTitle = params.positionTitle;

            let body = {
                //User Analytics
                "<user_name>": localStorage ? localStorage.getItem('username') : "USERNAME",
                "<dealer_code>": localStorage ? localStorage.getItem('dealer_code') : "DEALER_CODE",
                "<user_type>": localStorage ? localStorage.getItem('user_type') : "USER_TYPE",
                "<region>": localStorage ? localStorage.getItem('region_code') : "REGION",
                "<position_title>": positionTitle,
                "<job_code>": localStorage ? localStorage.getItem("job_code") : "JOB_CODE",
                "<spin_id>": localStorage ? localStorage.getItem("spin_id") : "SPIN_ID",

                //Page Analytics
                "<app_id>": config.appId,
                "<viewport>": config.winView,
                "<site_size>": config.site_size,

                "<page>": params.page ? params.page : "<page> is undefined",
                "<section>": params.section ? params.section : "<section> is undefined",
                "<subsection>": params.subsection ? params.subsection : "<subsection> is undefined",
                "<technology_name>": params.technologyName ? params.technologyName : "<technologyName> is undefined",
                "<media_title>": params.mediaTitle ? params.mediaTitle : "<media_title> is undefined",
                "<category>": params.category ? params.category : "<category> is undefined",
                "<content_title>": params.contentTitle ? params.contentTitle : "<content_title> is undefined",
                "<container>": params.container ? params.container : "<container> is undefined",
                "<label>": params.label ? params.label : "<label> is undefined",
                "<model_name>": params.modelName ? params.modelName : "<model_name> is undefined",
                "<model_year>": params.modelYear ? params.modelYear : "<model_year> is undefined",
                "<model_code>": params.modelCode ? params.modelCode : "<model_code> is undefined",
                "<trim>": params.trim ? params.trim : "<trim> is undefined",
                "<model_engine>": params.modelEngine ? params.modelEngine : "<model_engine> is undefined",
                "<model_cab>": params.modelCab ? params.modelCab : "<model_cab> is undefined",
                "<model_bed>": params.modelBed ? params.modelBed : "<model_bed> is undefined",
                "<model_drivetrain>": params.modelDrivetrain ? params.modelDrivetrain : "<model_drivetrain> is undefined",
                "<module>": params.module ? params.module : "<module> is not defined",
                // Skills Specific 
                "<category_topic>": params.categoryTopic ? params.categoryTopic : "<category_topic> is undefined",
                // Video Player Specific 
                "<events>": params.events ? params.events : "<events> is undefined",
                "<video_length>": params.videoLength ? params.videoLength : "<video_length> is undefined",
                // Articles Specific 
                "<article_type>": params.articleType ? params.articleType : "<articleType> is undefined",
                "<tile_position>": params.tilePosition ? params.tilePosition : "",
                // Cert & Dropdown specific 
                "<action>": params.action ? params.action : "<action> is undefined",
                // Search Specific
                "<keyword>": params.keyword ? params.keyword : "<keyword> is undefined",
                "<number_of_search_results>": params.numberOfSearchResults ? params.numberOfSearchResults : "<number_of_search_results> is undefined",
                // Hybrid App
                "<platform>": window.ReactNativeWebView ? "App" : "Web",
                "<os_version>": (sessionStorage?.getItem('app_device_os') || localStorage?.getItem('app_device_os')) ?? "<os_version> is undefined",                
                "<app_version>": (sessionStorage?.getItem('app_version') || localStorage?.getItem('app_version')) ?? "<app_version> is undefined",
            };
            
            let tag = params.tag ? params.tag : this.onClickTag;
            fireTag(tag, body);
        }
    }
}