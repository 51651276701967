import React from 'react';

const ProfileField = ({ label, value, onClick }) => {

  return (
    <div className="profile-field-container">
      <div className='profile-field-top'>
        <label className='profile-field-label'>{label}</label>
        {label.toLowerCase() === 'spin id' &&
          <div className={`spin-qr-code-link`} onClick={onClick}>SPIN QR CODE</div>
        }
      </div>
      <div className='profile-field-item'>{value !== undefined ? value : 'N/A'}</div>
    </div>
  );
};

export default ProfileField;
