import React, { useState, useEffect } from "react";
import VehicleSelector from "./vehicleSelector";
import { getAvailableModels, getVehicleYears } from "../../../services/vehicleService";
import AddVehicleButton from "./AddVehicleButton";
import { isMobileView } from "../../utils/getScreenSize";
import "../../../styles/css/beyondZero/vehicleSelectorGroup.css"
import getNavigation from "../../../services/navigationService";

const VehicleSelectorGroup = ({ electricVehicleData, headerData, showThirdComp, setShowThridComp, veh1Info, setVeh1Info, veh2Info, setVeh2Info, veh3Info, setVeh3Info, onVehicleDropdownChange = (vehicleIndex, key, value) => {} }) => {
    const [yearModelMappingAll, setYearModelMappingAll] = useState([]);
    const [yearModelMappingEVs, setYearModelMappingEVs] = useState([]);
    const [defaultComparisonInfo, setDefaultComparisonInfo] = useState(null);

    useEffect(() => {
        getAllModels();
    }, []);

    useEffect(() => {
        electricVehicleData && setYearModelMappingEVs(getYearModelMappingEVs(electricVehicleData));
    }, [electricVehicleData])

    useEffect(() => {
        veh1Info && setDefaultComparisonInfo(veh1Info);
    }, [veh1Info]);

    const getAllModels = () => {
        getNavigation("mainMenu").then(data => {
            const models = data.dropDownItems
                .find(item => item.title === 'Vehicles')
                .menuItems
                .filter(menuItem => menuItem.title !== 'Beyond Zero' && menuItem.title !== 'Non-Production')
                .map(dataItem => dataItem.menuItems)
                .flat()
                .map(modelItem => modelItem.model)
            getYearModelMappingAll(models);
        })
    };

    const getYearsWithRetry = (model) => {
        const delay = () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        }

        return new Promise(async (resolve, reject) => {
            for(var i = 0; i < 3; i++) {
                try {
                    const years = await getVehicleYears(model);
                    resolve(years);
                    return;
                } catch (error) {
                    console.log(`Error getting years data: ${error}`);
                }
                await delay();
            }
            reject();
        })
    }

    const getYearModelMappingAll = async (modelList) => {
        let results = await Promise.allSettled(modelList.map((modelName) => {
            return getYearsWithRetry(modelName).then(years => {
                return {model: modelName, years: years};
            })
        }
        ));
        
        // List of available model years
        const availableYears = [...new Set(results.map(result => result.value.years).flat())].sort().reverse();

        // Mapping of years to models available that year
        const yearModelMapping = availableYears.map((year) => {
            const filteredData = results.filter((item) => {
                return item.value.years.includes(year)
            });

            return {year: year, models: filteredData.map(data => data.value.model)};
        });

        setYearModelMappingAll(yearModelMapping);
    }; 

    const getYearModelMappingEVs = (data) => {
        // List of available model years for EV's from template data
        const availableYears = [...new Set(data.map(ev => ev.year))].sort().reverse();
        
        // Mapping of years to models available that year and trims for the models
        const yearModelMapping = availableYears.map((year) => {
            const evsForYear = data.filter(ev => ev.year === year);
            const availableModelsForYear = [...new Set(evsForYear.map(entry => entry.model))];
            const modelsWithTrims = availableModelsForYear.map(model => {
                const trims = evsForYear.filter(trim => trim.model === model);
                return {model: model, trims: trims};
            })
            return {year: year, models: modelsWithTrims};
        });

        return yearModelMapping;
    };

    return (
        <div className="bz-vehicle-selector-group">
            <div className="bz-vehicle-selector-group-content">
                <div className={`bz-vehicle-selector-group-selectors ${showThirdComp && 'has-third-comparison'}`}>
                    <VehicleSelector
                        header={headerData?.primaryVehicleHeader}
                        subheader={headerData?.primaryVehicleDescription}
                        yearModelMapping={yearModelMappingEVs}
                        setVehicleInfo={setVeh1Info}
                        evs
                        onDropdownChange={(key, value) => onVehicleDropdownChange(1, key, value)}
                    />
                    <VehicleSelector
                        header={headerData?.secondaryVehicleHeader}
                        subheader={headerData?.secondaryVehicleDescription}
                        yearModelMapping={yearModelMappingAll}
                        setVehicleInfo={setVeh2Info}
                        defaultComparisonInfo={defaultComparisonInfo}
                        onDropdownChange={(key, value) => onVehicleDropdownChange(2, key, value)}
                    />
                    {showThirdComp && !isMobileView(window.innerWidth) &&
                        <VehicleSelector
                            header={headerData?.tertiaryVehicleHeader}
                            subheader={headerData?.tertiaryVehicleDescription}
                            yearModelMapping={yearModelMappingAll}
                            setVehicleInfo={setVeh3Info}
                            onDropdownChange={(key, value) => onVehicleDropdownChange(3, key, value)}
                        />
                    }
                </div>
                {!isMobileView(window.innerWidth) &&
                    <div className={`bz-vehicle-selector-button-container ${showThirdComp && 'has-third-comparison'}`}>
                        <AddVehicleButton
                            onClick={() => setShowThridComp(value => !value)}
                            isClicked={showThirdComp}
                        />
                    </div>
                }
            </div>
        </div> 
    );
};

export default VehicleSelectorGroup;