import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';
import { requestRetry } from '../utils/requestRetry';

export function getNitroToken() {
  const baseUrl = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}`;
  const endpoint = `/nitro/getNitroToken/`;
  const fullUrl = baseUrl + endpoint
  const options = httpOption.GET();

  return fetch(fullUrl, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    }
  );
}

export async function getNitroUserData() {
  const [userLevels, UserPointBalance] = await Promise.all([getNitroUserLevels(), getNitroUserPointBalance()]);
  const EXPBalance = UserPointBalance.find(i => i.pointCategory?.name === "XP");

  const response = {
    nitroCurrentLevel: userLevels?.current.name,
    nitroCurrentLevelPoints: EXPBalance?.currentBalance - userLevels?.current.points,
    nitroUserPoints: EXPBalance?.currentBalance,
    nitroPointsNeeded: userLevels?.next.pointsNeeded,
    nitroNextLevel: userLevels?.next.name,
    nitroNextLevelPoints: userLevels?.next.points
  }
  return response
}

export async function getNitroUserLevels() {
  const baseUrl = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}`;
  const endpoint = `/nitro/getNitroUserLevels`;
  const fullUrl = baseUrl + endpoint
  const options = httpOption.GET();

  return fetch(fullUrl, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    }
  );
}

export async function getNitroUserPointBalance() {
  const baseUrl = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}`;
  const endpoint = `/nitro/getNitroUserPointBalance`;
  const fullUrl = baseUrl + endpoint
  const options = httpOption.GET();

  return fetch(fullUrl, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    }
  );
}

async function postUserAction(name, metadata) {
  const body = {
    data: {
      actionTime: Date.now(),
      metadata: {
        ...metadata,
        product: window.ReactNativeWebView ? 'ENGAGE APP' : 'ENGAGE WEB',
        url: `${window.location.pathname}${window.location.search}`,
      },
      name: name,
    },
    userId: localStorage.getItem('spin_id'),
  };
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/postUserAction`;
  const options = httpOption.POST();
  options.body = JSON.stringify(body);

  const response = await fetch(url, options);
  if (response.status >= 200 && response.status < 300) {
    console.log("Nitro User Action Posted: " + name);
    return Promise.resolve();
  }
  return await Promise.reject(response);
}

export function postLoginAction() {
  const metadata = {};
  postUserAction('ENGAGE_LOGIN', metadata);
}

export function postViewPageAction(url) {
  const metadata = { url: url };
  postUserAction('VIEW_PAGE', metadata);
}

export function postViewArticleAction(url) {
  const metadata = { url: url };
  postUserAction('VIEW_ARTICLE', metadata);
}

export function postWatchVideoAction(videoId, percentWatched) {
  const metadata = { videoId: videoId, percent_watched: percentWatched };
  postUserAction('WATCH_VIDEO', metadata);
}

export function postEngageSearchAction(searchString) {
  const metadata = { searchString };
  postUserAction('ENGAGE_SEARCH', metadata);
}

export function postFeaturesSpecsAction(category, label, year, trim, engine) {
  const metadata = { name: 'FEATURES_SPECS', category, label, year, trim, engine };
  postUserAction('USE_FEATURE', metadata);
}

export function postAMMAction(modelYear, modelName, modelGrade) {
  const metadata = { name: 'AUDIO_MULTIMEDIA', model_year: modelYear, model_name: modelName, model_grade: modelGrade };
  postUserAction('USE_FEATURE', metadata);
}

export function postTrimWalkAction(category, label, model, year, trim) {
  const curYear = year ? year.toString() : year;
  const metadata = { name: 'TRIM_WALK', category, label, model, curYear, trim };
  postUserAction('USE_FEATURE', metadata);
}

export function postVinLookupAction() {
  const metadata = { name: 'VIN_LOOKUP' };
  postUserAction('USE_FEATURE', metadata);
}

export function postTcopAction(category, label) {
  const metadata = { name: 'TCOP', category, label };
  postUserAction('USE_FEATURE', metadata);
}

export function postColorAction(category, label) {
  const metadata = { name: 'COLORS', category, label};
  postUserAction('USE_FEATURE', metadata);
}

export function postTrimSelectorAction(name, category, label) {
  const metadata = { name: name, category, label };
  postUserAction('USE_FEATURE', metadata);
}

export function postTowCapacityAction(modelName, modelYear) {
  const metadata = { name: 'TOW_CAPACITY', model_name: modelName, model_year: modelYear };
  postUserAction('USE_FEATURE', metadata);
}

export function postCompetitiveAdvantagesAction(category, label, modelName, year, jato) {
  const featureName = jato ? 'COMPETITIVE_ADVANTAGES_JATO' : 'COMPETITIVE_ADVANTAGES_H2H';
  const curYear = year ? year.toString() : year;
  const metadata = { name: featureName, category, label, modelName, curYear };
  postUserAction('USE_FEATURE', metadata);
}

//TO DO
export function postQuizCompletedAction() {
  const metadata = {};
  postUserAction('QUIZ_COMPLETED', metadata);
}

//TO DO
export function postBeRecognizedAction() {
  const metadata = {};
  postUserAction('BE_RECOGNIZED', metadata);
}

//TO DO
export function postRecognizeAction() {
  const metadata = {};
  postUserAction('RECOGNIZE', metadata);
}

//TO DO
export function postSelfReportingAction() {
  const metadata = {};
  postUserAction('SELF_REPORTING', metadata);
}

//TO DO
export function postAdminAction() {
  const metadata = {};
  postUserAction('ADMIN', metadata);
}

// NEW XP
export async function getNitroRecentActivities(count) {
  const baseUrl = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}`;
  const endpoint = `/nitro/recent-activities?returnCount=${count}`;
  const fullUrl = baseUrl + endpoint
  const options = httpOption.GET();

  return fetch(fullUrl, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}

export async function getNitroBadges(count) {
  const baseUrl = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}`;
  const endpoint = `/nitro/badges`;
  const fullUrl = baseUrl + endpoint
  const options = httpOption.GET();

  return fetch(fullUrl, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}

export async function getUpdatedMission(missionId) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/get-mission/${missionId}`;
  const options = httpOption.GET();

  try {
    const response = await fetch(endpoint, options);

    if (response.ok) {
      const data = await response.json();
      return data.response;
    } else {
      throw new Error(`Failed to get updated mission data for ${missionId}: ${response.status}`);
    }
  } catch (error) {
    throw (error);
  }
}

export async function getXPMissions(type) {
  const typeParam = type ? `?type=${type}` : '';
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/missions${typeParam}`;
  const options = httpOption.GET();

  try {
    const response = await fetch(endpoint, options);

    if (response.ok) {
      const data = await response.json();
      return data.response;
    } else {
      throw new Error(`Failed to get user's missions: ${response.status}`);
    }
  } catch (error) {
    throw (error);
  }
}

export async function getXPMissionLabels(missionsData) {
    const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/mission-labels`;
    const options = httpOption.POST();
    options.body = JSON.stringify(missionsData);

    try {
      const response = await fetch(endpoint, options);

      if (response.ok) {
        const data = await response.json()
        return data;
      } else {
        throw new Error(`Failed to get mission's labels: ${response.status}`);
      }
    } catch (error) {
      throw (error);
    }
}

export async function getXPMissionDetails(missionId) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/mission-details/${missionId}`;
  const options = httpOption.GET();

  try {
    const response = await fetch(endpoint, options);

    if (response.ok) {
      const data = await response.json();
      return data.response;
    } else {
      throw new Error(`Failed to get mission details for ${missionId}: ${response.status}`);
    }
  } catch (error) {
    throw (error);
  }
}

export async function getTournamentMissionsDetails(missions) {
  try {
      const missionDetailsPromises = missions.map(async mission => {
          const missionDetailsResult = await getXPMissionDetails(mission.id);
          return { ...mission, details: missionDetailsResult };
      });

      const missionDetails = await Promise.all(missionDetailsPromises);
      return missionDetails;
  } catch (error) {
      throw error;
  }
}

export async function getXPLeaderboardConfig() {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/leaderboard-config`;
  const options = httpOption.GET();

  try {
    const response = await fetch(endpoint, options);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to get leaderboard config: ${response.status}`);
    }
  } catch (error) {
    throw (error);
  }
}

export async function getXPLeaderboardData(leaderboardId, groupClassIds) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/leaderboard/?leaderboardId=${leaderboardId}&groupClassId=${groupClassIds}`;;
  const options = httpOption.GET();

  try {
    const response = await fetch(endpoint, options);

    if (response.ok) {
      const data = await response.json();
      return data.response;
    } else {
      throw new Error(`Failed to get leaderboard ${leaderboardId} data: ${response.status}`);
    }
  } catch (error) {
    throw (error);
  }
}

export async function getXPLeadersDealerships(leaderbaordData) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/leaderboard-dealerships/`;
  const options = httpOption.POST();
  options.body = JSON.stringify(leaderbaordData);

  try {
    const response = await fetch(endpoint, options);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to get leaderboard users' dealership data: ${response.status}`);
    }
  } catch (error) {
    throw (error);
  }
}

export async function getQuizOverview(quizId) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/quiz/${quizId}`;
  const options = httpOption.GET();

  try {
    const response = await fetch(endpoint, options);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(
        `Failed to get quiz overview for quiz ${quizId}: ${response.status}`
      );
    }
  } catch (error) {
    console.log(`Failed to get quiz overview for quiz ${quizId}: ${error}`);
    throw error;
  }
}

export async function getQuizQuestions(quizId) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/quiz/${quizId}/questions`;
  const options = httpOption.GET();

  try {
    const response = await fetch(endpoint, options);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(
        `Failed to get questions for quiz ${quizId}: ${response.status}`
      );
    }
  } catch (error) {
    console.log(`Failed to get questions for quiz ${quizId}: ${error}`)
    throw error;
  }
}

export async function getQuizDetails(quizId) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/quizDetails/${quizId}`;
  const options = httpOption.GET();
  try {
    const response = await fetch(endpoint, options);
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(
        `Failed to get questions for quiz details ${quizId}: ${response.status}`
      );
    }
  } catch (error) {
    console.log(`Failed to get questions for quiz details ${quizId}: ${error}`)
    throw error;
  }
}

export async function sendQuestionAnswers(quizId, questionId, selectedAnswers) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/quiz/${quizId}/questions/${questionId}/responses`;
  const options = httpOption.POST();
  options.body = JSON.stringify({ selectedAnswers });
  try {
    return await requestRetry(endpoint, options);
  } catch (error) {
    console.log( `Failed to post answers for quiz ${quizId} and question ${questionId}: ${error}`);
    throw error;
  }
}

export async function postQuizSubmit(quizId) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/quiz/${quizId}/submit`;
  const options = httpOption.POST();
  try {
    return await requestRetry(endpoint, options);
  } catch (error) {
    console.log(`Failed to submit quiz ${quizId}: ${error}`);
    throw error;
  }
}

export async function selfReportAction(missionId, ruleId) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/self-report/${missionId}/${ruleId}`;
  const options = httpOption.POST();

  try {
    const response = await fetch(endpoint, options);

    if (response.ok) {
      const data = await response.json()
      return data;
    } else {
      throw new Error(
        `Failed to relf report action for ${ruleId}: ${response.status}`
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getTournamentTransactionsByMissions(missions, tournamentsData) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/getTotalsFromTransactions`;
  const options = httpOption.POST();
  const trimmedTournamentsData = getTrimmedTournamentsData(tournamentsData);
  options.body = JSON.stringify({ missions, tournamentsData: trimmedTournamentsData });

  try {
    const response = await fetch(endpoint, options);
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(
        `Failed to get user's transactions`
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getTournamentPointsEarned(missions) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/getIndividualFromTransactions`;
  const options = httpOption.POST();
  options.body = JSON.stringify({ missions });

  try {
    const response = await fetch(endpoint, options);
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to get user's transactions for ${JSON.stringify(missions)}`);
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getTournamentLeadersScores(leaders, tournamentsData) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/get-leaderboard-scores`;
  const options = httpOption.POST();
  const trimmedTournamentsData = getTrimmedTournamentsData(tournamentsData);
  options.body = JSON.stringify({ leaders, tournamentsData: trimmedTournamentsData });

  try {
    const response = await fetch(endpoint, options);
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to get leaderboard average scores.");
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getTournamentConfig() {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/tournaments-leaderboard-config`;
  const options = httpOption.GET();

  try {
    const response = await fetch(endpoint, options);
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to get leaderboard config.");
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getTournamentLeaderboard(leaderboardId) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/tournament-leaderboard/?leaderboardId=${leaderboardId}`;
  const options = httpOption.GET();

  try {
    const response = await fetch(endpoint, options);

    if (response.ok) {
      const data = await response.json();
      return data.response;
    } else {
      throw new Error(`Failed to get tournament leaderboard ${leaderboardId} data: ${response.status}`);
    }
  } catch (error) {
    throw (error);
  }
}

export async function postTournamentTransaction(amount, details) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/postTournamentTransaction`;
  const options = httpOption.POST();
  options.body = JSON.stringify({ amount, details });

  try {
    const response = await fetch(endpoint, options);
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to post tournament transaction.");
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getTournamentLeadersRegion(leaders) {
  const endpoint = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/nitro/get-leaderboard-regions`;
  const options = httpOption.POST();
  options.body = JSON.stringify({ leaders });
  try {
    const response = await fetch(endpoint, options);
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to get tournament leaderboard regions.");
    }
  } catch (error) {
    throw (error);
  }
}

function getTrimmedTournamentsData(tournamentsData) {
  const tournamentsDataTrimmed = {
    tournamentSection: {
      rounds: []
    }
  };

  if (tournamentsData && tournamentsData.tournamentSection && tournamentsData.tournamentSection.rounds) {
    tournamentsData.tournamentSection.rounds.forEach(round => {
      const roundToAdd = {
        missions: round.missions.map(mission => ({ id: mission.id, numberOfQustions: mission.numberOfQustions })),
      };
      tournamentsDataTrimmed.tournamentSection.rounds.push(roundToAdd);
    });
    return tournamentsDataTrimmed;
  } else {
    console.error("Rounds property not found in tournamentsData.");
    return;
  }
}
