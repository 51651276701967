import DropdownV2 from "../../../../components/molecules/dropdownV2";
import AppSettings from "../../../../appsettings";
import { dropdownSelect } from "../thirdPartyComparison.analytics"
import { updateMakeAnalytics, updateModelAnalytics, updateTrimAnalytics } from '../thirdPartyComparison.analytics'
import { isMobileView } from "../../../utils/getScreenSize";
import { useState } from "react";

export const ThirdPartyComparisonVehicleCard = (props) => {
    const [imgError, setImgError] = useState(false);

    const getImage = () => {
        
        let imgElement;
        if (props?.selectedTrimId) {
            imgElement = <img className='vehicle-image' src={props?.imageUrl} onError={() => setImgError(true)} alt='Vehicle'/>
        } else {
            imgElement = <img className='image-placeholder' src={`${AppSettings.AWSImgRepo.resources}en/${props?.shadowImage}`} alt='Vehicle'/>; 
        }

        if(imgError) {
            imgElement = <img className='image-placeholder' src={`${AppSettings.AWSImgRepo.resources}en/${props?.shadowImage}`} alt='Vehicle'/>; 
        }
        return imgElement;
    };

    const handleMakeChange = (option) => {
        updateMakeAnalytics();
        props?.updateMake(props?.vehicleNum, option.value);
        dropdownSelect({
            page: props?.analyticsData?.page,
            typeSelect: "Make",
            contentTitle: option.value,
            modelName: props?.analyticsData?.modelName,
            modelYear: props?.analyticsData?.modelYear
        })
    };

    const handleModelChange = (option) => {
        updateModelAnalytics();
        props?.updateModel(props?.vehicleNum, option.value.modelId, option.value.modelName, option.value.year);
        dropdownSelect({
            page: props?.analyticsData?.page,
            typeSelect: "Model",
            contentTitle: option.value?.year + ' ' + option.value?.modelName,
            modelName: props?.analyticsData?.modelName,
            modelYear: props?.analyticsData?.modelYear
        })
    };

    const handleTrimChange = (option) => {
        setImgError(false);
        updateTrimAnalytics();
        props?.updateTrim(props?.vehicleNum, option.value.trimId, option.label, option.value.imageUrl);
        dropdownSelect({
            page: props?.analyticsData?.page,
            typeSelect: "Trim",
            contentTitle: option.label,
            modelName: props?.analyticsData?.modelName,
            modelYear: props?.analyticsData?.modelYear
        })
    };

    return (
        <div className="filter-card">
            <div className="info-section">
                <div className="image-container">
                    {getImage()}
                </div>
                {
                    props?.selectedTrimId ?
                        <div className="year-make-model">
                            <span className="year">{props?.year}</span>
                            <span className="make-model">{`${props?.selectedMake} ${props?.selectedModel}`}</span>
                        </div>
                    :
                        <div className="year-make-model-placeholder">
                            {`Vehicle${isMobileView(window.innerWidth) ? ' 2' : ''}`}
                        </div>
                }
                <div className="trim">
                    <span>{props?.selectedTrimName}</span>
                </div>
            </div>
            <div className="dropdown-section">
                {props?.vehicleNum !== 0 ? 
                    <DropdownV2 
                        style={'white'}
                        options={props?.makeOptions}
                        value={props?.selectedMake}
                        placeholder={'Select'}
                        onValueChange={(option) => handleMakeChange(option)}
                        rootClassName={'filter-dropdown'}
                    />
                    :
                    <div className="toyota-placeholder">
                        Toyota
                    </div>
                }
                
                <DropdownV2 
                    style={'white'}
                    options={props?.modelOptions?.map(item => ({label:`${item.year} ${item.model}`, value: {modelId: item.modelId, year: item.year, modelName: item.model}}))}
                    value={props?.selectedModel !== '' ? `${props?.year} ${props?.selectedModel}` : null}
                    defaultValue={'Select'}
                    placeholder={'Select'}
                    onValueChange={(option) => handleModelChange(option)}
                    rootClassName={'filter-dropdown'}
                    disabled={props?.selectedMake === ''}
                />
                <DropdownV2 
                    style={'white'}
                    options={props?.trimOptions?.map(item => ({label: item.label, value: {trimId: item.trimId, imageUrl: item.imageUrl}}))}
                    value={props?.selectedTrimName !== '' ? `${props?.selectedTrimName}` : null}
                    defaultValue={props?.loadingTrims ? 'Loading Trims...' : 'Select'}
                    placeholder={props?.loadingTrims ? 'Loading Trims...' : 'Select'}
                    onValueChange={(option) => handleTrimChange(option)}
                    rootClassName={'filter-dropdown'}
                    disabled={props.selectedModel === ''}
                />
            </div>
        </div>
    );
};