import CarouselComponent from '../carousel/carousel.component';
import '../../styles/css/towingPayload-vehicleFilterMenu.css';
import DropdownV2 from '../../components/molecules/dropdownV2';
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { isDesktopView, isMobileView, isTabletView } from '../utils/getScreenSize';
import { DataListInputDropdown } from "../../components/molecules/dataListInputDropdown";
import * as analytics from './towingPayload-analytics';


export const FilterMenu = (props) => {
    const[model,setModel] = useState('')
    const[trim,setTrim] = useState('Trim')
    const[cab,setCab] = useState('Cab')
    const[bed,setBed] = useState('Bed')
    const[engine,setEngine] = useState('Engine')
    const[driveTrain,setDrivetrain] = useState('Drivetrain')

    function generateDropdown(options, onChangeFunction, setStateFunction, placeholder) {
        const downChevron = props.imagePath + 'Down_Arrow_Black.png';
        const dropdownClosedArrow = <img className="towing-FilterDropdown-dropdown-arrow" src={downChevron}/>;
        const dropdownOpenArrow = <img className="towing-FilterDropdown-dropdown-arrow-upside-down" src={downChevron} />;
        return (
            <div className="towing-FilterDropdown">
                <DropdownV2
                    arrowClosed={dropdownClosedArrow}
                    arrowOpen={dropdownOpenArrow}
                    options={options}
                    onValueChange={value => {
                        onChangeFunction(value.value);
                        setStateFunction(value.value);
                        //Hides the Featured button in the vehicleFilter page
                        props.showFeatured(false);
                        props.clickAnalytics && analytics.filterDropdownClick(props.clickAnalytics, props.vehicle.modelYear, props.vehicle.modelName, value.value, placeholder);
                    }}
                    value={options.length==1 ? options[0] : placeholder}
                    menuClassName={`towing-FilterDropdown-dropdown-menu`}
                    placeholderClassName={`towing-FilterDropdown-placeholder`}
                />
            </div>
        )
    }

    function generateInputDataListDropdown(options, onChangeFunction, setStateFunction, placeholder) {
        return(
            <div className="towing-FilterDataListInputDropdown">
                <DataListInputDropdown
                    placeholder={"Model Code"}
                    options={options}
                    onSelect={option => {
                        onChangeFunction(option.label);
                        setStateFunction(option.label);
                        //Hides the Featured button in the vehicleFilter page
                        props.showFeatured(false);
                        props.clickAnalytics && analytics.modelCodeDropdownClick(props.clickAnalytics, props.vehicle.modelYear, props.vehicle.modelName, option.label);
                    }}
                    onInput={val => {
                        if (val === "") {
                            resetDropdown();
                        }
                    }}
                    value={model}
                />
            </div>
        )
    }

    useEffect(() => {
    let inputField = document.getElementsByClassName('autocomplete-input inputField');
    if(inputField!= null && inputField.length != 0){
        for(var i =0;i<inputField.length;i++){
        inputField[i].addEventListener('keydown',handleKeyPress);
        }
    }
    },[]);

    function handleKeyPress(e) {
        if(e.key === "Backspace" && props.resetData == false){
            let inputValue = e.target.value.substring(0,e.target.value.length -1);
            resetDropdown(inputValue);
        }
    }

    const resetDropdown = (searchValue) =>{
        props.setResetData(true);
        setModel(searchValue);
        setTrim('Trim');
        setCab('Cab');
        setBed('Bed');
        setEngine('Engine');
        setDrivetrain('Drivetrain');
        props.showFeatured(true);
        props.clickAnalytics && analytics.clearSelectionsClick(props.clickAnalytics, props.vehicle.modelYear, props.vehicle.modelName);
    };
    const getDropdowns = () => {
        return<div className = "tp-vehicleFilterMenu" >
            <div>
                <div className = "tp-FilterHeader">Select or enter model code:</div>
                {generateInputDataListDropdown(props.filterOptions.modelCode, ((modelCode)=>{props.setFilterChange({key:'modelCode',value:modelCode})}),((modelCode) => setModel(modelCode)))}
                <div className = "tp-FilterHLine"></div>
            </div>
            <div> 
                <div className = "tp-FilterHeader">Or choose a configuration:</div>
                <div className = "tp-FilterContent">
                    {props.filterOptions.trimName[0] && generateDropdown(props.filterOptions.trimName, ((trimName)=>{props.setFilterChange({key:'trimName',value:trimName})}),((trimName) => setTrim(trimName)), trim)}
                    {props.filterOptions.cab[0] && generateDropdown(props.filterOptions.cab, ((cab)=>{props.setFilterChange({key:'cab',value:cab})}),((cab) => setCab(cab)), cab)}
                    {props.filterOptions.bedLength[0] && generateDropdown(props.filterOptions.bedLength, ((bedLength)=>{props.setFilterChange({key:'bedLength',value:bedLength})}),((bedLength) => setBed(bedLength)), bed)}
                    {props.filterOptions.engine[0] && generateDropdown(props.filterOptions.engine, ((engine)=>{props.setFilterChange({key:'engine',value:engine})}),((engine) => setEngine(engine)), engine)}
                    {props.filterOptions.driveType[0] && generateDropdown(props.filterOptions.driveType, ((driveType)=>{props.setFilterChange({key:'driveType',value:driveType})}),((driveType) => setDrivetrain(driveType)), driveTrain)}
                    <div>
                        <button className = "tp-FilterHeader-clearSelections" >
                            <span className = "tp-FilterHeader-clearSelectionsText" onClick={() => resetDropdown('')} >Clear Selections</span>
                        </button>  
                    </div>
                </div>
            </div>
            
    </div>

    }
    return props.filterOptions && getDropdowns()
}
