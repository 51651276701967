import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { postUserActivityRecord } from '../../services/userActivityService.js';
import "../../styles/css/resources-v2/resources-v2-base.css";
import "../../styles/css/vehicleDetails/details-base.css";
import SideBarMenu from "../sidebarMenu/sidebarMenu.js";
import { loadingStatus, processSpecsNavItems, updateSidebarNavData } from "../VPRouter/VPUtils.js";
import { pageLoad } from "./vehicleDetails.analytics.js";
import AppSettings from "../../appsettings.js";
import "../../styles/css/vp-header/base.css";
import { getDynamoName, getUrlModelName, getS3PathName, formatModelListItemName } from "../utils/vehicleName.js";
import Header from "../../components/molecules/VPheader/header.js";
import TrimSelector from "../../components/molecules/VPheader/trimSelector.js";
import { getTrims } from "../../services/trimsService.js";
import DisclaimersComponent from "../disclaimers/disclaimers.component.js";
import TCOPComponent from "../TCOP/TCOP.component";
import AccessoriesComponent from "../accessories/accessories.component";
import FeaturesAndSpecs from "../featuresAndSpecs/featuresAndSpecs";
import { getTrimOptions } from "../../services/trimOptionsService.js";
import { postTrimSelectorAction } from "../../services/nitroService.js";
import getGlossary from "../../services/glossaryService.js";
import { PopupTooltipContext } from "../../components/contexts/popupTooltipContext.js";
import { isDesktopView } from "../utils/getScreenSize.js";
import { getTooltip } from "../VPRouter/VPUtils.js";
import getAnalytics from "../../services/analyticsService.js";

import Colors from "./colors/colors.js";
import ColorsV2 from "./colors/colorsV2.js";
import FeaturesSpecs from "./featuresAndSpecs/featuresSpecs.js";
import OptionsPackages from "./optionsPackages/optionsPackages.js";
import Accessories from "./accessories/accessoriesSubpage.js";
import * as analytics from "../sidebarMenu/sidebarMenu.analytics.js"

const VehicleDetails = (props) => {
  const [currentDetailsPage, setCurrentDetailsPage] = useState(null);
  const [s3PathName, setS3PathName] = useState('');
  const [trims, setTrims] = useState([]);
  const [trimsList, setTrimsList] = useState([]);
  const [selectedTrim, setSelectedTrim] = useState("");
  const [secondSelectedTrim, setSecondSelectedTrim] = useState("");
  const [selectedTrimData, setSelectedTrimData] = useState({});
  const [trimOptionsData, setTrimOptionsData] = useState(null);
  const [trimOptionsLoading, setTrimOptionsLoading] = useState(true);
  const [comparisonActive, setComparisonActive] = useState(false);
  const [secondSelectedYear, setSecondSelectedYear] = useState(props.comparisonYears[0]);
  const [glossaryTerms, setGlossaryTerms] = useState(null);
  const [glossaryData, setGlossaryData] = useState(null);
  const [glossaryAnalytics, setGlossaryAnalytics] = useState(null);

  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const disclaimerComponentRef = useRef();

  const { setVisiblePopupTooltip, setCurrentPopupTooltip, checkCurrentTooltipMatch } = useContext(PopupTooltipContext);


  useEffect(() => {
    if(currentDetailsPage?.urlPath) {
      pageLoad({
        page: `${currentDetailsPage?.parentTitle ? `${currentDetailsPage.parentTitle}:` : ``}${currentDetailsPage?.title}`,
        modelName: props?.model,
        modelYear: props?.year,
      });

      postUserActivityRecord();
    }
  }, [currentDetailsPage?.urlPath]);

  useEffect(() => {
    if (props.menuItems) {
      const isSpecPage = params.subPage === "specifications";
      const queryPage = isSpecPage ? `?type=${props.route?.type}` : '';
      const hasFeatSpecFilters =   props.route?.trim || props.route?.featSpec || props.route?.engine;

      const currentPageData = props.menuItems
        .map(m => m.nestedItems ? m.nestedItems.map(i => ({ ...i, parentTitle: m.title })) : m)
        .flat()
        .find(m => "urlPath" in m && m.urlPath === params.subPage + queryPage);
      
      const shouldSetPageDetailsOrReplace = 
        props.menuItems &&
          (isSpecPage
            ? [loadingStatus.DONE, loadingStatus.FAILED].includes(
                props.isLoadingFeaturesAndSpecs
              )
            : true);

      if(shouldSetPageDetailsOrReplace){
        if (currentPageData) {
          setCurrentDetailsPage(currentPageData);
          return;
        } else if(isSpecPage){
          const firstSpecsItemUrl = props.menuItems.find(m => m.title.toUpperCase() === "SPECIFICATIONS")?.nestedItems[0]?.urlPath;
          if (firstSpecsItemUrl && !hasFeatSpecFilters) {
            history.replace(`/product/${getUrlModelName(params.model)}/${params.year}/details/${firstSpecsItemUrl}`);
            return;
          }
        }

        if (!hasFeatSpecFilters) history.replace(`/product/${getUrlModelName(params.model)}/${params.year}/details/${props.menuItems[0].urlPath}`);
      }
    }
  }, [props.menuItems, params.subPage, location.search, props.isLoadingFeaturesAndSpecs]);

  useEffect(() => {
    async function fetchPath() {
      const s3PathName = await getS3PathName(getDynamoName(props.model));
      setS3PathName(s3PathName);
    }
    fetchPath();
  }, [props.model, props.year]);

useEffect(() => {
  loadTrims();
  getTrimOptionsData();
  getGlossary()
    .then((response) => {      
      setGlossaryData(response.Items);
      const filteredTerms = response.Items.map(item=>item.term);
      setGlossaryTerms(filteredTerms);
    })
    getAnalytics('glossary')
    .then(tooltipAnalytics => {
      setGlossaryAnalytics(tooltipAnalytics.clickAnalytics);
    })
}, [props.year, props.model]);

  const loadTrims = async () => {
    const response = await getTrims(getDynamoName(props.model), props.year, "en");
    setTrims(response?.trims);
    const trimNames = [...new Set(response?.trims?.map(t => t.name))];
    const trimFilterOptions = trimNames?.map(t => {
      const trimModels = response?.trims?.filter(m => m.name === t);
      return (
        {
          name: t,
          trimImageFilename: trimModels.length ? trimModels[0].trimImageFilename : '',
          trimModels: trimModels?.map(m => ({modelCode: m.modelCode, fullName: formatModelListItemName(m.fullName, m.name)}))
        }
      )
    });
    setTrimsList(trimFilterOptions);
    
    if (trimFilterOptions?.length) {
      const allTrims = flatTrims(trimFilterOptions);
      const trim = props.route?.trim ? allTrims.find(t => props.route?.trim === t.name || props.route?.trim === t.fullName) : trimFilterOptions[0];
      setSelectedTrim(trim);
    }
  };

  const getTrimOptionsData = async () => {
    const response = await getTrimOptions(getDynamoName(props.model), props.year, "en");
    setTrimOptionsData(response.trimOptions);
    setTrimOptionsLoading(false);
  };

  const postTrimAction = (label) => {
    let name = `${currentDetailsPage?.parentTitle ? `${currentDetailsPage.parentTitle}_` : ``}${currentDetailsPage?.title}`;

    //replace non-alphanumeric chracters with a space
    name = name.replace(/\W/g, ' ');
    
    //replace one or more consecutive spaces with an underscore and capitalize
    name = name.replace(/\s+/g, '_').toUpperCase();

    postTrimSelectorAction(name, "Trim Selector", label);
  };

  const applyTooltip = (text, tooltipData, surroundingInlineText) => {
    return (
      <>
        <span>
          <span className="inline-tooltip-text" onClick={(event)=>{ setCurrentPopupTooltip(surroundingInlineText +"."+(tooltipData ? tooltipData.term : "")); event.stopPropagation();}}>{text}</span>
          {(isDesktopView(window.innerWidth) && checkCurrentTooltipMatch(surroundingInlineText +"."+(tooltipData.term ? tooltipData.term : ""))) && getTooltip(tooltipData, setVisiblePopupTooltip, history, glossaryAnalytics, 'Vehicle Page: Details')}
        </span>
        {(!isDesktopView(window.innerWidth) && checkCurrentTooltipMatch(surroundingInlineText +"."+(tooltipData.term ? tooltipData.term : ""))) && getTooltip(tooltipData, setVisiblePopupTooltip, history, glossaryAnalytics, 'Vehicle Page: Details')}
      </>
    );
  }

  const getDetailsPageComponent = () => {
    const pageTitle = currentDetailsPage?.parentTitle || currentDetailsPage?.title;
    switch(pageTitle.toLowerCase()) {
      case 'colors':
        return <Colors
          trims={trims}
          model={props.model}
          year={props.year}
          s3PathName={s3PathName}
          selectedTrim={selectedTrim}
          trimOptions={trimOptionsData}
          disclaimerComponentRef={disclaimerComponentRef}
          colorOptionsLoading={trimOptionsLoading}
          title={`${currentDetailsPage?.parentTitle ? `${currentDetailsPage.parentTitle}:` : ``}${currentDetailsPage?.title}`}
        />
      case 'colors - new':
        return <ColorsV2
          trims={trims}
          model={props.model}
          year={props.year}
          s3PathName={s3PathName}
          selectedTrim={selectedTrim}
          trimOptions={trimOptionsData}
          disclaimerComponentRef={disclaimerComponentRef}
          colorOptionsLoading={trimOptionsLoading}
          title={`${currentDetailsPage?.parentTitle ? `${currentDetailsPage.parentTitle}:` : ``}${currentDetailsPage?.title}`}
        />
      case 'features':
      case 'specifications':
        return props.isLoadingFeaturesAndSpecs === loadingStatus.DONE && <FeaturesSpecs
          currentFeaturesSpecsPage={currentDetailsPage}
          trims={trims}
          comparisonYears={props.comparisonYears}
          model={props.model}
          year={props.year}
          title={`${currentDetailsPage?.parentTitle ? `${currentDetailsPage.parentTitle}:` : ``}${currentDetailsPage?.title}`}
          s3PathName={s3PathName}
          selectedTrim={selectedTrim}
          featuresAndSpecsData={props.featuresAndSpecsData}
          selectedFeaturesAndSpecs={props.route?.featSpec}
          disclaimerComponentRef={disclaimerComponentRef}
          glossaryTerms={glossaryTerms}
          glossaryData={glossaryData}
          applyTooltip={applyTooltip}
        />
      case 'options & packages':
        return <OptionsPackages 
        currentOptionsPackagesPage={currentDetailsPage} 
        trims={trims}
        comparisonYears={props.comparisonYears}
        model={props.model}
        year={props.year}
        title={`${currentDetailsPage?.parentTitle ? `${currentDetailsPage.parentTitle}:` : ``}${currentDetailsPage?.title}`}
        s3PathName={s3PathName}
        selectedTrim={selectedTrim}
        disclaimerComponentRef={disclaimerComponentRef}
        optionsAndPackagesData={trimOptionsData}
        trimOptionsLoading={trimOptionsLoading}
        device={props.device}
        glossaryTerms={glossaryTerms}
        glossaryData={glossaryData}
        applyTooltip={applyTooltip}/>
      case 'accessories':
          return <Accessories
          modelName={props?.model}
          modelYear={props?.year}
          title={currentDetailsPage?.title}
          glossaryTerms={glossaryTerms}
          glossaryData={glossaryData}
          applyTooltip={applyTooltip} />
      default:
        return <></>;
    }
  };

  const flatTrims = (trims) => trims.map(trim => {
    return trim.trimModels.map(tModel => ({
      name: trim.name,
      fullName: `${trim.name} (${tModel.fullName.trim()})`
    }))
  }).flat();

  const sideBarNestedMenuClickAnalytics = (item) => {
    analytics.subNavItemClick({
      subsection: params.model,
      page: params?.page,
      category: item?.title,
      positionTitle: 'Resources',
      modelName: params.model,
      modelYear: params.year,
    });
  }

  const sideBarPageClickAnalytics = (item, parentTitle) => {
    analytics.subNavItemClick({
      subsection: params.model,
      category: item?.title,
      page: params?.page,
      positionTitle: !parentTitle ? 'Resources' : 'Comparison',
      modelName: params.model,
      modelYear: params.year
    })
  }

  return (
    <div className="vehicle-details">
      <SideBarMenu
        menuItems={props.menuItems}
        device={props.device}
        setSelectedPage={setCurrentDetailsPage}
        selectedPage={currentDetailsPage}
        urlPrefix={`/product/${getUrlModelName(params.model)}/${params.year}/${params.page}/`}
        sideBarNestedMenuClickAnalytics={sideBarNestedMenuClickAnalytics}
        sideBarPageClickAnalytics={sideBarPageClickAnalytics}
      />
      <div className="details-container">
        <Header
          title={
            currentDetailsPage?.parentTitle
              ? currentDetailsPage.parentTitle.toUpperCase()
              : currentDetailsPage?.title
          }
          subtitle={
            currentDetailsPage?.parentTitle ? currentDetailsPage?.title : "Trims"
          }
        />
        {currentDetailsPage?.parentTitle?.toLowerCase() !== "accessories" && (
          <TrimSelector
            trimsList={trimsList}
            selectedTrim={selectedTrim}
            setSelectedTrim={setSelectedTrim}
            postTrimAction={postTrimAction}
            analyticsData={{
              page: `${currentDetailsPage?.parentTitle ? `${currentDetailsPage.parentTitle}:` : ``}${currentDetailsPage?.title}`,
              modelName: props?.model,
              modelYear: props?.year
            }}
            />
          )}
        <div className="details-content">
          {currentDetailsPage && getDetailsPageComponent()}
        </div>
      </div>
      <DisclaimersComponent
        template={`${props?.year}_${props?.model.toLowerCase()}`}
        ref={disclaimerComponentRef}
      />
    </div>
  );
};

export default VehicleDetails;
