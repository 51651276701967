import React, { useEffect, useState, useRef, forwardRef } from 'react';
import '../../styles/css/accordion.css'
import applyCustomMarkdown from '../../screens/utils/applyCustomMarkdown';

/*

required props
    rows
        list of objects like
            {
                title: string or jsx, required
                    row text before expanding
                    use applyMarkdown here and build jsx if multiple elements/styles
                body: jsx, required
                    row content below title after expanding
                key: string or number, required
                    unique non-jsx key for expand/collapse functionality
                scrollExpand/expand: boolean, optional (ex: glossaryAccordion.js)
                    only one object in the list should be true
                    scrollExpand: scrolls to and expands that row
                    expand: only expands that row
                    if more than one, will only scroll and expand the first true row in the list
                rowDataAnalytics: object, optional (ex: featureLookupModelResultCard.js)
                    specific data for generic row expand/collapse analytics function
            }
recommended props
    headerTitle
        string or jsx
        accordion title above rows
        if not provided, no title displayed

optional props
    expandCollapseAll
        boolean
        if true, display and enable expand/collapse all
        if false, hide and disable expand/collapse all
    scrollCallback
        function
        if passed and a row should be scrolled to and expanded, 
        this function will be called after scroll and expand completes 
        (ex. Used in comparisonSection for features and specs accordion inner scroll)
    expandIcon/collapseIcon
        jsx (image element)
    autoCollapse
        boolean
        if true then only one row can be open at once. Opening a second row will 
        automaically collapse the open row. This should not be combined with
        expandCollapseAll

analytics props
    collapseAllAnalytics
    expandAllAnalytics
    termAnalyticsClick(term, isExpand)
*/

export const Accordion = forwardRef((props, collapseAccordionRef) => {
    const [activeRowKeys, setActiveRowKeys] = useState([]);
    const scrollExpandRowRef = useRef();
    const firstRun = useRef(true);

    const foundScrollExpandRow = props.rows?.find(e => e.scrollExpand || e.expand);

    useEffect(() => {
        // Add first found scrollExpandRow == true to activeRowKeys        
        if (foundScrollExpandRow) {
            setActiveRowKeys([foundScrollExpandRow.key]);
            // Scroll the active accordion row into view
            if(foundScrollExpandRow.scrollExpand) {
                scrollExpandRowRef?.current?.scrollIntoView({ behavior: "smooth" });
                props.scrollCallback && props.scrollCallback()
            }
        } else {
            setActiveRowKeys([...activeRowKeys]);
        }
    }, [props.rows]);

    useEffect(() => {
        if(firstRun.current){
            firstRun.current = false;
        }
        else if(collapseAccordionRef?.current){
            setActiveRowKeys([]); 
        }
    }, [collapseAccordionRef?.current]);

    useEffect(() => {
        if (props.shouldCollapseAllChildren) {
            setActiveRowKeys([]);
        }
    }, [props.shouldCollapseAllChildren]);

    useEffect(() => {
        if (props.shouldExpandAllChildren) {
            setActiveRowKeys([...props.rows.map(e => e.key)]);
        }
    }, [props.shouldExpandAllChildren]);

    const expandCollapseRow = (key, term, rowAnalyticsData, parentTitle) => {
        if (activeRowKeys.includes(key)) {
            if(props.autoCollapse) {
                setActiveRowKeys([]);
            } else {
                setActiveRowKeys(activeRowKeys.filter(i => i != key));
            }
            props.termAnalyticsClick && props.termAnalyticsClick(term, false, rowAnalyticsData, parentTitle);
        } else {
            if(props.autoCollapse) {
                setActiveRowKeys([key]);
            } else {
                setActiveRowKeys([...activeRowKeys, key]);
            }
            props.termAnalyticsClick && props.termAnalyticsClick(term, true, rowAnalyticsData, parentTitle);
        }

        props.expandCollapseCallback && props.expandCollapseCallback(key);
    }

    const collapseAll = () => {
        setActiveRowKeys([]);
        props.collapseAllAnalytics && props.collapseAllAnalytics();
        // Using new Date().getTime() to get a truthy value to force a re-render
        props.setShouldCollapseAllChildren && props.setShouldCollapseAllChildren(new Date().getTime());
        props.setShouldExpandAllChildren && props.setShouldExpandAllChildren(false);
    }

    const expandAll = () => {
        setActiveRowKeys([...props.rows.map(e => e.key)]);
        props.expandAllAnalytics && props.expandAllAnalytics();
        props.setShouldCollapseAllChildren && props.setShouldCollapseAllChildren(false);
        // Using new Date().getTime() to get a truthy value to force a re-render
        props.setShouldExpandAllChildren && props.setShouldExpandAllChildren(new Date().getTime());
    }

    const getAccordionHeader = () => (
        /* If the accordion is empty, render disabled action buttons */
        /* If the accordion is NOT empty, render enabled action buttons */
        <div className="accordion-header">
            {props.headerTitle && <div className="accordion-header-title">{typeof props.headerTitle == "string" ? applyCustomMarkdown(props.headerTitle, "bold") : props.headerTitle}</div>}
            {props.expandCollapseAll && <div className="accordion-expand-collapse-group">
                <div className="accordion-expand-collapse" onClick={() => expandAll()}>
                    <span className="expand-collapse-icon">＋</span>
                    <span className="expand-collapse-text">EXPAND ALL</span>
                </div>
                <div className="accordion-expand-collapse" onClick={() => collapseAll()}>
                    <span className="expand-collapse-icon">－</span>
                    <span className="expand-collapse-text">COLLAPSE ALL</span>
                </div>
            </div>}
        </div>
    )

    const htmlToString = (e, stringList) => {
        //flatten html to only string elements
        if (typeof e != "string") {
            if (e?.props?.children) {
                [e.props.children].flat().forEach(i => htmlToString(i, stringList))
            } else return;
        } else {
            stringList.push(e);
        }
    };

    const getTitleString = (title) => {
        // if title passed in as jsx/html, flatten to string for analytics
        let titleText;
        if (typeof title != "string" && typeof title.props?.children != "string") {
            titleText = [];
            title.props?.children.forEach(t => {
                htmlToString(t, titleText)
            })
            titleText = titleText.join(' ');
        } else if (typeof title.props?.children == "string") {
            titleText = title.props.children
        } else {
            titleText = title;
        }
        return titleText;
    }

    const getAccordionRows = () => (
        <div className="accordion-rows">
            {props.rows && props.rows.map(item => {
                return <div key={item.key} role="listitem" className={`accordion-row ${(activeRowKeys.includes(item.key) || props.alwaysExpanded) ? 'expanded' : 'collapsed'}`} ref={foundScrollExpandRow && foundScrollExpandRow.key == item.key ? scrollExpandRowRef : null}>
                    <div className="accordion-row-header" onClick={() => !props.alwaysExpanded && expandCollapseRow(item.key, getTitleString(item.title), item.rowAnalyticsData, item?.parentTitle)}>
                        <div className="title">{typeof item.title == "string" ? applyCustomMarkdown(item.title, "bold") : item.title}</div>
                        <div className="expand-collapse-icon">{(activeRowKeys.includes(item.key)) ? props.collapseIcon || "－" : props.expandIcon || "＋"}</div>
                    </div>
                    <div className={`accordion-row-content ${!activeRowKeys.includes(item.key) && !props.alwaysExpanded && "hidden"}`}>{item.body}</div>
                </div>
            })}
        </div>
    )
    return (
        <div className={`accordionV2 ${props.className}`}>
            {(props.headerTitle || props.expandCollapseAll) && getAccordionHeader()}
            {getAccordionRows()}
        </div>
    );
});
