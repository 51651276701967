import AppSettings from "../../../appsettings";
import React from "react";
import { useRef, useEffect } from "react";
import {isDesktopView} from "../../utils/getScreenSize.js"
import "../../../styles/css/resources-v2/trim-walk-accordion-body.css";
import Slider from "react-slick";

const TrimWalkAccordionBody = (props) => {

    const sliderRef = useRef(null);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        beforeChange: (oldIndex, newIndex) => {
            props?.setSelectedVehicle(newIndex)
        }
    };

    useEffect(() => {
        sliderRef?.current?.slickGoTo(props?.selectedVehicle, true)
    }, [props?.selectedVehicle])

    const getAvailabilityIcon = (availability) => {
        switch (availability?.toLowerCase()) {
            case "standard":
                return ( 
                    <img 
                        className='standard-icon' 
                        src={AppSettings.AWSImgRepo.resources + `en/standard.png`} 
                        alt=''
                    />
                )
            case "option":
                return ( 
                    <img 
                        className='option-icon' 
                        src={AppSettings.AWSImgRepo.resources + `en/option.png`} 
                        alt=''
                    />
                )
            case "package":
                return ( 
                    <img 
                        className='package-icon' 
                        src={AppSettings.AWSImgRepo.resources + `en/package.png`} 
                        alt=''
                    />
                )
            case "available":
                return <div className="h2h-availablity-indication">A</div>
            case "not available":
                return <div className="h2h-not-availablity-indication">—</div>
            default:
                return ( 
                    <img 
                        className='standard-icon' 
                        src={AppSettings.AWSImgRepo.resources + `en/standard.png`} 
                        alt=''
                    />
                )
        }
    };

    const generateFeatureColumn = (colData, num) => {
        if(colData?.vehicleFeatures?.length > 0) {
            return <div className="trim-walk-column" key={`${colData.fullName}-featureColumn-${num}`}>
                {colData?.vehicleFeatures?.map((d, index) => 
                    <div className='trim-walk-feature-row' key={`${colData.fullName}-${index}`}>
                        {getAvailabilityIcon(d.mainData)}
                        <div className="trim-walk-feature-name">{d.featureName}</div>
                    </div>
                )}
            </div>
        } else {
            return <div className="trim-walk-accordion-no-results">
                No additional features added
            </div>
        }
    };

    const generatePackageColumn = (colData, num) => {
        if(colData?.vehiclePackages?.length > 0) {
            return <div className="trim-walk-column" key={`${colData.fullName}-packageColumn-${num}`}>
                {colData?.vehiclePackages?.map((d, index) => 
                    <div className='trim-walk-package-row' key={`${colData.fullName}-${index}`}>
                        <div className="trim-walk-package-title">
                            {d.title}
                            {` (${d.code} ${d.msrp})`}
                        </div>
                        <div className="trim-walk-package-description">{d.description}</div>
                    </div>
                )}
            </div>
        } else {
            return <div className="trim-walk-accordion-no-results">
                No additional packages added
            </div>
        }
    };

    const accordionHeader = (vehicles) => {
        return (
            <div className="trim-walk-column-header">
                {vehicles.map((vehicle, index) => {
                    return <div className="trim-walk-column-header-content" key={index}>{vehicle.fullName}</div>
                })}
            </div>
        );
    }

    return (
        <div className="trim-walk-accordion-body">
                {isDesktopView(window.innerWidth) ?
                    <>
                        {accordionHeader(props?.data)}
                        <div className="trim-walk-data-section">
                            {props?.data?.map((column, index) => {
                                return props?.packages ?
                                    generatePackageColumn(column, index)
                                :
                                    generateFeatureColumn(column, index)
                                
                            })}
                        </div>
                    </>
                :
                    <Slider {...settings} ref={sliderRef}>
                        {props?.data?.map((column) => {
                            return (
                                <>
                                    {accordionHeader([column])}
                                    <div className="trim-walk-data-section">
                                        {props?.packages ?
                                            generatePackageColumn(column, 0)
                                        :
                                            generateFeatureColumn(column, 0)}
                                    </div>
                                </>
                            )
                        })}
                    </Slider>
                }
        </div>
    );
}

export default TrimWalkAccordionBody;
