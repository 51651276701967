import Modal from "react-responsive-modal";
import appsettings from "../../../appsettings";
import QRCode from "../../../components/atoms/qrCode";
import { postMessageToApp } from "../../utils/hybridAppHelpers";
import { addToAppleWalletClick, addToGoogleWalletClick, closeQRCodeModalClick } from "./profilePage.analytics";

const imagePath = appsettings.AWSImgRepo.appLinks;
const appleImage = imagePath + 'add-to-apple-wallet-logo.png';
const googleWalletImage = imagePath + 'google_wallet_button.png';
const WALLET_MESSAGE = 'Add to wallet';

const ProfilePageQrCodeModal = ({ modalVisible, onClose, userData, analyticsData }) => {

  const handleCloseModal = () => {
    onClose();
    closeQRCodeModalClick(analyticsData?.clickAnalytics);
  };

  return (
    <Modal
      open={modalVisible}
      onClose={handleCloseModal}
      classNames={{
        modal: "qr-code-modal",
        modalContainer: "qr-code-modal-container",
      }}
    >
      <QRCode profile={userData} size={214} />
      <span className="user-full-name">
        {userData.firstName} {userData.lastName}
      </span>
      <span className="user-spin-number">SPIN NUMBER: {userData.spinId}</span>
      {
        // Commented out for now until we get the Add to wallet bug fixed
        // (window.ReactNativeWebView && (localStorage?.getItem('app_version')?.localeCompare('12.0.4') > 0) || sessionStorage?.getItem('app_version')?.localeCompare('12.0.4') > 0)
        false &&
          // Commented out for now until we get the Add to wallet bug fixed
          // (window.localStorage.app_device_os.includes("ios") ? (
            (true ?(
            <a
              className="add-to-wallet-container"
              onClick={() => {
                postMessageToApp("Add to wallet", WALLET_MESSAGE)
                addToAppleWalletClick(analyticsData?.clickAnalytics);
              }}
            >
              <img className="appleWalletImage" src={appleImage} />
            </a>
          ) : (
            <a
              className="add-to-wallet-container"
              onClick={() => {
                postMessageToApp("Add to wallet", WALLET_MESSAGE)
                addToGoogleWalletClick(analyticsData?.clickAnalytics);
              }}
            >
              <img className="googleWalletImage" src={googleWalletImage} />
            </a>
          ))
      }
    </Modal>
  );
};

export default ProfilePageQrCodeModal;
