import React, { useState, useEffect, useRef } from "react";
import AppSettings from '../../../appsettings';
import TrimComparisonComponent from "./trimComparisonComponent";
import { getDynamoName, formatModelListItemName } from "../../utils/vehicleName.js";
import { getTrims } from "../../../services/trimsService.js";
import applyMarkdown from '../../utils/applyMarkdown';
import AccordionLegend from "../../../components/molecules/accordionLegend.js";
import { Accordion } from "../../../components/molecules/Accordion.js";
import FeaturesAccordionContent from "./featuresAccordionContent";
import SpecsAccordionContent from "./specsAccordionContent";
import { NestedAccordion } from "../../../components/molecules/nestedAccordion";
import { accordionClick } from "../vehicleDetails.analytics";
import { getVPTooltips} from "../../VPRouter/VPUtils";
import { getFeaturesAndSpecs } from "../../../services/featuresAndSpecsService.js";
import Skeleton from "react-loading-skeleton";
import { areStringsEqual } from "../../utils/helpers";
import { convertNameToSlug } from '../../VPRouter/VPUtils.js';
import "../../../styles/scss/vehicleDetails/accordion-expand-collapse.scss"

const FeaturesSpecs = (props) => {
    const [comparisonActive, setComparisonActive] = useState(false);
    const [secondSelectedYear, setSecondSelectedYear] = useState(props.year);
    const [secondTrimsOptions, setSecondTrimsOptions] = useState([]);
    const [secondSelectedTrim, setSecondSelectedTrim] = useState("");
    const [secondFeaturesAndSpecsData, setSecondFeaturesAndSpecsData] = useState(null);
    const collapseAccordionRef = useRef(props.currentFeaturesSpecsPage?.title?.toLowerCase());
    const [tooltips, setTooltips] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [shouldCollapseAllChildren, setShouldCollapseAllChildren] = useState(false);
    const [shouldExpandAllChildren, setShouldExpandAllChildren] = useState(false);
    useEffect(() => {
        loadTrims();
        getFeaturesAndSpecsData();
    }, [secondSelectedYear]);
    useEffect(() => {
        collapseAccordionRef.current = props.currentFeaturesSpecsPage?.title?.toLowerCase();
        let data;
        let terms = [];
        switch (props.currentFeaturesSpecsPage?.parentTitle.toLowerCase()){
            case "features":
                data = props?.featuresAndSpecsData[`${props.currentFeaturesSpecsPage?.title}`];
                terms = data?.map((outerHeader) => outerHeader?.featureSpecs?.map((feature) => feature?.Description)).flat();
                break;
            case "specifications":
                data = props?.featuresAndSpecsData.Specs.find((spec)=> spec.name.toLowerCase() == props.currentFeaturesSpecsPage?.title.toLowerCase());
                terms = data?.featureSpecs?.map((feature) => feature?.Description).flat();
                break;
            default:
                break;
        }
        
        props?.glossaryTerms?.length > 0 && getVPTooltips(terms, props?.glossaryTerms, setTooltips);
    }, [props.currentFeaturesSpecsPage?.title?.toLowerCase(), props?.glossaryTerms]);


    const loadTrims = async () => {
        setIsLoading(true);
        const response = await getTrims(getDynamoName(props.model), secondSelectedYear, "en");
        const trimNames = [...new Set(response?.trims?.map(t => t.name))];
        const trimFilterOptions = trimNames?.map(t => {
            const trimModels = response?.trims?.filter(m => m.name === t);
            return (
                {
                    name: t,
                    trimImageFilename: trimModels?.length ? trimModels[0].trimImageFilename : '',
                    trimModels: trimModels?.map(m => ({modelCode: m.modelCode, fullName: formatModelListItemName(m.fullName, m.name)}))
                }
            )
        });
        setSecondTrimsOptions(trimFilterOptions);
        setSecondSelectedTrim(trimFilterOptions[0]);
        setIsLoading(false);
    };
      
    const getFeaturesAndSpecsData = async () => {
        const response = await getFeaturesAndSpecs(getDynamoName(props.model), secondSelectedYear, "en");
        setSecondFeaturesAndSpecsData(response?.featuresAndSpecs);
    };  
    
    const getAccordion = () => {
        switch(props.currentFeaturesSpecsPage?.parentTitle?.toLowerCase()) {
          case 'features':
            switch (props.currentFeaturesSpecsPage?.title?.toLowerCase()) {
              case "exterior":
                return buildNestedAccordion(props.featuresAndSpecsData?.Exterior, secondFeaturesAndSpecsData?.Exterior);
              case "interior":
                return buildNestedAccordion(props.featuresAndSpecsData?.Interior, secondFeaturesAndSpecsData?.Interior);
              case "safety & convenience":
                return buildNestedAccordion(props.featuresAndSpecsData?.['Safety/Convenience'], secondFeaturesAndSpecsData?.['Safety/Convenience']);
              default:
                return <></>;
            }
          case 'specifications':
            const specsData = props.featuresAndSpecsData?.Specs?.filter((spec) => areStringsEqual(spec.name, props.currentFeaturesSpecsPage.title))?.[0]?.featureSpecs;
            const comparisonSpecsData = secondFeaturesAndSpecsData?.Specs?.filter((spec) => areStringsEqual(spec.name, props.currentFeaturesSpecsPage.title))?.[0]?.featureSpecs;
            return buildSimpleAccordion(specsData, comparisonSpecsData);
          default:
            return null;
        }
    };


    const buildNestedAccordion = (featureData = [], comparisonFeatureData = []) => {
        const categoryNames = [...new Set([...featureData, ...comparisonFeatureData].map(f => f.name))]
        const accordionData = categoryNames?.map((category, index) => {
            const primaryFeatureData = featureData?.find(f => f.name === category)?.featureSpecs || [];
            const secondaryFeatureData = comparisonFeatureData?.find(f => f.name === category)?.featureSpecs || [];
            const featureNames = [...new Set([...primaryFeatureData, ...secondaryFeatureData].map(f => f["Description"]))]
            const innerAccordionData = featureNames?.map((feature, nestedIndex) => {
                const modelAvailability = {
                    "trimName": props.selectedTrim.name,
                    "availability": props.selectedTrim?.trimModels
                        ?.map(t => ({
                            availability: primaryFeatureData?.find(f => f.Description === feature)?.[parseInt(t.modelCode, 10)] || "–",
                            ...t
                        }))
                }
                const comparisonModelAvailability = comparisonActive && {
                    "trimName": secondSelectedTrim.name,
                    "availability": secondSelectedTrim.trimModels
                        ?.map(t => ({
                            availability: secondaryFeatureData?.find(f => f.Description === feature)?.[parseInt(t.modelCode, 10)] || "–",
                            ...t
                        }))
                }

               
                return (
                    {
                        innerHeader: tooltips[feature] ? applyMarkdown(tooltips[feature], props.disclaimerComponentRef.current, null, true, props?.applyTooltip, props.glossaryData)
                        : applyMarkdown(feature, props.disclaimerComponentRef.current, null, true, props?.applyTooltip, props.glossaryData),
                        body: <FeaturesAccordionContent
                            modelAvailability={modelAvailability}
                            comparisonModelAvailability={comparisonModelAvailability}
                        />,
                        key: nestedIndex
                    }
                )
            })

            const scrollExpand = props?.selectedFeaturesAndSpecs === convertNameToSlug(category);
            return (
                {
                    outerHeader: category,
                    innerAccordionData: innerAccordionData,
                    scrollExpand
                }
            )
       })
   
       return (
            <div>
                <AccordionLegend left type={"sop"} />
                <NestedAccordion
                    expandCollapseAll
                    setShouldCollapseAllChildren={setShouldCollapseAllChildren}
                    shouldCollapseAllChildren={shouldCollapseAllChildren}
                    setShouldExpandAllChildren={setShouldExpandAllChildren}
                    shouldExpandAllChildren={shouldExpandAllChildren}
                    ref={collapseAccordionRef}
                    data={accordionData} 
                    outerAccordionClassName={'vehicle-page-accordion'}
                    outerAnalyticsClick={(term, isExpand) => {
                        accordionClick({
                            page: props?.title,
                            term: term,
                            isExpand: isExpand,
                            modelName: props.model,
                            modelYear: props.year,
                            isNested: false,
                            trim: props?.selectedTrim?.name
                        });
                    }}
                    innerAnalyticsClick={(term, isExpand, _, parentTitle) => {
                        accordionClick({
                            page: props?.title,
                            term: term,
                            isExpand: isExpand,
                            modelName: props.model,
                            modelYear: props.year,
                            isNested: true,
                            trim: props?.selectedTrim?.name,
                            positionTitle: parentTitle
                        });
                    }}
                />
            </div>
        ); 
     }

     const buildSimpleAccordion = (specsData = [], comparisonSpecsData = []) => {
        const categoryNames = [...new Set([...specsData, ...comparisonSpecsData].map(s => s["Description"]))]
        const accordionData = categoryNames?.map((category) => {
            const modelSpecs = {
                "trimName": props.selectedTrim.name,
                "specs": props.selectedTrim.trimModels
                    ?.map(t => ({
                        spec: specsData?.find(f => f["Description"] === category)?.[parseInt(t.modelCode, 10)] || "–",
                        ...t
                    }))
            }
            const comparisonModelSpecs = comparisonActive && {
                "trimName": secondSelectedTrim.name,
                "specs": secondSelectedTrim.trimModels
                    ?.map(t => ({
                        spec: comparisonSpecsData?.find(f => f["Description"] === category)?.[parseInt(t.modelCode, 10)] || "–",
                        ...t
                    }))
            }

            const scrollExpand = props?.selectedFeaturesAndSpecs === convertNameToSlug(category);
            return (
                {
                    title: tooltips && tooltips[category] ? applyMarkdown(tooltips[category], props.disclaimerComponentRef.current, null, true, props?.applyTooltip, props.glossaryData)
                    : applyMarkdown(category, props.disclaimerComponentRef.current, null, true, props?.applyTooltip, props.glossaryData),
                    body: <SpecsAccordionContent
                            modelSpecs={modelSpecs}
                            comparisonModelSpecs={comparisonModelSpecs}
                            disclaimerRef={props.disclaimerComponentRef.current}
                        />,
                    key: category,
                    scrollExpand
                }
            )
        })
   
       return (
         <div>
           <Accordion
                expandCollapseAll
                ref={collapseAccordionRef}
                rows={accordionData}
                className="vehicle-page-accordion specs-accordion"
                termAnalyticsClick={(term, isExpand) => {
                accordionClick({
                    page: props?.title,
                    term: term,
                    isExpand: isExpand,
                    modelName: props.model,
                    modelYear: props.year,
                    isNested: false,
                    trim: props?.selectedTrim?.name
                });
            }}
           />
         </div>
       ); 
     }

    return <>
        <div className="trim-comparison-component">
            <TrimComparisonComponent
              comparisonActive={comparisonActive}
              setComparisonActive={setComparisonActive}
              comparisonYears={props.comparisonYears}
              s3PathName={props.s3PathName}
              selectedTrim={props.selectedTrim}
              secondSelectedTrim={secondSelectedTrim}
              setSecondSelectedTrim={setSecondSelectedTrim}
              secondSelectedYear={secondSelectedYear}
              setSecondSelectedYear={setSecondSelectedYear}
              secondTrimsOptions={secondTrimsOptions}
              year={props.year}
              analyticsData={{
                page: props?.title,
                modelName: props?.model,
                modelYear: props?.year,
                trim: props?.selectedTrim?.name
              }}
            />
        </div>
        {isLoading ? 
            <div className="feats-specs-skeleton-loading-container">
                {props.currentFeaturesSpecsPage?.parentTitle?.toLowerCase() === 'features' && 
                    <div className="accordion-legend-loading">
                        <Skeleton height={30} width={300} />
                    </div>
                }
                <div className="feats-specs-accordion-loading">
                    <Skeleton count={5} height={30} /> 
                </div>
            </div>
            
        : 
            getAccordion()
        }
    </>}

export default FeaturesSpecs;
