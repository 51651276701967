import React, { useState, useEffect } from "react";
import DropdownV2 from "../../../components/molecules/dropdownV2";
import AppSettings from "../../../appsettings";
import { getTrims } from "../../../services/trimsService";
import { getDynamoName, getFormattedName, getS3PathName } from "../../utils/vehicleName";

const VehicleSelector = ({ header, subheader, yearModelMapping, setVehicleInfo, defaultComparisonInfo, evs, onDropdownChange = (key, value) => {} }) => {
    const [selectedYear, setSelectedYear] = useState(null);
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [trims, setTrims] = useState([]);
    const [selectedTrim, setSelectedTrim] = useState(null);
    const [s3PathName, setS3PathName] = useState(null);
    const [loadingTrims, setLoadingTrims] = useState(false);
    const [vehicleImage, setVehcileImage] = useState(null);
    const [listenForDefaultChange, setListenForDefaultChange] = useState(true);

    useEffect(() => {
        selectedYear && updateYear();
    }, [selectedYear]);

    useEffect(() => {
        selectedModel && updateModel();
        selectedModel && getS3PathName(getDynamoName(selectedModel)).then(name => {
            setS3PathName(name);
        }).catch(error => {
            console.log(`Error getting S3 Pathname: ${error}`)
        })
    }, [selectedModel]);

    useEffect(() => {
        selectedTrim && setVehcileImage(`${AppSettings.AWSImgRepo.vehicle}${selectedYear}/${s3PathName}/en/${selectedTrim?.trimImageFilename}`);
    }, [selectedTrim, s3PathName]);

    useEffect(() => {
        selectedTrim && updateTrim();
    }, [selectedTrim]);

    useEffect(() => {
        // The async nature of fetching trims requires this useEffect for default comparison updates
        if(trims && listenForDefaultChange && defaultComparisonInfo?.defaultComparisonModelCode) {
            setDefaultCompTrim();
        }
    }, [trims])

    useEffect(() => {
        if(defaultComparisonInfo) {
            setListenForDefaultChange(true);
            setSelectedYear(defaultComparisonInfo.modelYear);
            setSelectedModel(defaultComparisonInfo.model);

            // Special case for when only the trim is changed
            if(selectedYear === defaultComparisonInfo.modelYear && selectedModel === defaultComparisonInfo.model) {
                setDefaultCompTrim();
            }
        }
    }, [defaultComparisonInfo]);

    const setDefaultCompTrim = () => {
        const defaultComparisonTrim = trims.find(trim => trim.value.modelCode === defaultComparisonInfo.defaultComparisonModelCode);
        setSelectedTrim(defaultComparisonTrim.value);
        setListenForDefaultChange(false);
    };

    const updateYear = () => {
        let modelsForYear;
        if(!evs) {
            modelsForYear = yearModelMapping.find(item => item.year === Number(selectedYear)).models;
        } else {
            modelsForYear = yearModelMapping.find(item => item.year === selectedYear).models.map(data => data.model);
        }

        const formattedModels = modelsForYear.map(model => {
            return {
                value: model,
                label: getFormattedName(model),
            };
        }).sort((a, b) => {
            return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
        });

        setModels(formattedModels);
    };

    const updateModel = () => {
        setLoadingTrims(true);
        getTrims(selectedModel, selectedYear).then(data => {
            if (data.trims) {
                if(!evs) {
                    const trimData = data.trims.map(trim => formatTrimData(trim));
                    setTrims(trimData);
                } else {
                    const trimData = formatEVTrimData(data.trims);
                    setTrims(trimData);
                }
            } else {
                setTrims([]);
            }

        })
        .catch(error => {
            console.log(`Error fetching trims: ${error}`);
        })
        .finally(() => {
            setLoadingTrims(false);
        });
    };

    const formatTrimData = (trim) => {
        const value = {
            name: trim.name,
            fullName: trim.fullName,
            model: selectedModel,
            modelCode: trim.modelCode,
            modelYear: selectedYear,
            trimImageFilename: trim.trimImageFilename,
            startingPrice: trim?.msrp,
            costOfOwnership: trim?.costOfOwnership,
            buildLocation: trim?.buildLocation,
        };

        return {
            label: trim.fullName,
            value: value,
        };
    };

    const formatEVTrimData = (vehicleData) => {
        const hybridTrimData = yearModelMapping.find(yearItem => yearItem.year === selectedYear)
            .models
            .find(modelItem => modelItem.model === selectedModel)
            .trims
        
        const evTrims = vehicleData.filter(trim => hybridTrimData.map(code => code.hybridModelCode).includes(trim.modelCode));
        return evTrims.map((evTrim) => {
            const matchingTrim = hybridTrimData.find(templateItem => templateItem.hybridModelCode === evTrim.modelCode);
            const value = {
                name: evTrim.name,
                fullName: evTrim.fullName,
                model: selectedModel,
                modelCode: evTrim.modelCode,
                modelYear: selectedYear,
                trimImageFilename: evTrim.trimImageFilename,
                nameFromTemplate: matchingTrim.hybridTrim,
                defaultComparisonTrimNameFromTemplate: matchingTrim.nonHybridTrim,
                defaultComparisonModelCode: matchingTrim.nonHybridModelCode,
                startingPrice: evTrim?.msrp,
                costOfOwnership: evTrim?.costOfOwnership,
                buildLocation: evTrim?.buildLocation,
            };

            return {
                label: matchingTrim.hybridTrim,
                value: value,
            };
        });
    };

    const updateTrim = () => {
        setVehicleInfo(selectedTrim);
    };

    const handleYearDropChange = (year) => {
        setSelectedYear(year.toString());
        setSelectedModel(null);
        setSelectedTrim(null);
        onDropdownChange('Year', year)
    };

    const handleModelDropChange = (model) => {
        setSelectedModel(model);
        setSelectedTrim(null);
        onDropdownChange('Model', model)
    };

    const handleTrimDropChange = (trim) => {
        setSelectedTrim(trim);
        onDropdownChange('Trim', trim?.name)
    }

    return (
        <div className="bz-vehicle-selector">
            <div className="selector-header-section">
                <div className="selector-header">{header}</div>
                <div className="selector-subheader">{subheader}</div>
            </div>

            <div className="selector-image-and-dropdowns-section">
                <div className="bz-selector-vehicle-image-container">
                    <img
                        className="bz-selector-vehicle-image"
                        src={vehicleImage || `${AppSettings.AWSImgRepo.beyondZero}bz-default-vehicle.png`}
                        alt='vehicle'
                        onError={() => setVehcileImage(`${AppSettings.AWSImgRepo.beyondZero}bz-default-vehicle.png`)}
                    />
                </div>
                <DropdownV2
                    style={"white"}
                    placeholder={'YEAR'}
                    defaultValue={'YEAR'}
                    options={yearModelMapping?.map(item => item.year)}
                    onValueChange={event => handleYearDropChange(event.value)}
                    value={selectedYear ? selectedYear : ''}
                />
                <DropdownV2
                    style={"white"}
                    placeholder={'MODEL'}
                    defaultValue={'MODEL'}
                    options={models}
                    onValueChange={event => handleModelDropChange(event.value)}
                    disabled={selectedYear === null}
                    value={selectedModel}
                />
                <DropdownV2
                    style={"white"}
                    placeholder={loadingTrims ? 'Loading Trims...' : 'TRIM'}
                    defaultValue={loadingTrims ? 'Loading Trims...' : 'TRIM'}
                    disabled={selectedModel === null}
                    options={trims}
                    onValueChange={event => handleTrimDropChange(event.value)}
                    value={selectedTrim?.nameFromTemplate || selectedTrim?.fullName}
                />
            </div>
        </div>
    );
}

export default VehicleSelector;