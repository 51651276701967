import React, { useState, useEffect, useMemo, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { postUserActivityRecord } from "../../services/userActivityService.js";
import SideBarMenu from "../sidebarMenu/sidebarMenu.js";
import { updateSidebarNavData } from "../VPRouter/VPUtils.js";
import { getUrlModelName } from "../utils/vehicleName.js";
import { getFilteredArticles } from "../../services/articlesService";
import { HeadToHead } from "./headToHead/headToHead.js";
import { TrimWalk } from "./trimWalk/trimWalk.js";
import { RelatedContent } from "./relatedContent/relatedContent.js";
import { ThirdPartyComparison } from "./thirdPartyComparison/thirdPartyComparison.js";
import { pageLoad } from "../vehicleDetails/vehicleDetails.analytics.js";
import getAnalytics from "../../services/analyticsService.js";
import "../../styles/css/resources-v2/resources-v2-base.css";
import Header from "../../components/molecules/VPheader/header.js";
import LocalIncentives from "../localIncentives/localIncentives.js";
import getGlossary from "../../services/glossaryService.js";
import { PopupTooltipContext } from "../../components/contexts/popupTooltipContext.js";
import { isDesktopView } from "../utils/getScreenSize.js";
import { getTooltip } from "../VPRouter/VPUtils.js";
import * as analytics from "../sidebarMenu/sidebarMenu.analytics.js"


const VehicleResourcesV2 = (props) => {
  const [currentResourcesPage, setCurrentResourcesPage] = useState(null);
  const [articles, setArticles] = useState(null);
  const [glossaryData, setGlossaryData] = useState(null);
  const [glossaryTerms, setGlossaryTerms] = useState(null);
  const [glossaryAnalytics, setGlossaryAnalytics] = useState(null);

  const { setVisiblePopupTooltip, setCurrentPopupTooltip, checkCurrentTooltipMatch } = useContext(PopupTooltipContext);

  const recommendedArticles = useMemo(() => {
    return articles?.filter((article) => article?.isRecommended);
  }, [articles]);

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    if (props.year && props.model) {
      postUserActivityRecord();

      const queryTag = `vehicle-${props.model}-${props.year}`;
      getFilteredArticles(queryTag)
        .then((payload) => {
          setArticles(payload.Items);
        })
        .catch((error) => {
          console.log(
            `ERROR: failed to get ${props.model}-${props.year} articles. ${error}`
          );
        });

        getGlossary()
          .then((response) => {      
            setGlossaryData(response.Items);
            const filteredTerms = response.Items.map(item=>item.term);
            setGlossaryTerms(filteredTerms);
        })
        getAnalytics('glossary')
          .then(tooltipAnalytics => {
            setGlossaryAnalytics(tooltipAnalytics.clickAnalytics);
          })
    }
  }, [props.year, props.model]);

  useEffect(() => {
    if (props.menuItems) {
      const currentPageData = props.menuItems
        .map((m) =>
          m.nestedItems
            ? m.nestedItems.map((i) => ({ ...i, parentTitle: m.title }))
            : m
        )
        .flat()
        .find((m) => "urlPath" in m && m.urlPath == params.subPage);

      if (currentPageData) {
        setCurrentResourcesPage(currentPageData);
      } else {
        history.replace(
          `/product/${getUrlModelName(params.model)}/${params.year}/resources/${
            props.menuItems[0].urlPath
          }`
        );
      }
    }
  }, [props.menuItems, params.subPage]);

  useEffect(() => {
    if(currentResourcesPage?.urlPath) {
      pageLoad({
        page: `${currentResourcesPage?.parentTitle ? `${currentResourcesPage.parentTitle}:` : ``}${currentResourcesPage?.title}`,
        modelName: props?.model,
        modelYear: props?.year,
      });
      postUserActivityRecord();
    }
  }, [currentResourcesPage?.urlPath]);

  const applyTooltip = (text, tooltipData, surroundingInlineText) => {
    return (
      <>
        <span>
          <span className="inline-tooltip-text" onClick={(event)=>{setCurrentPopupTooltip(surroundingInlineText +"."+(tooltipData ? tooltipData.term : "")); event.stopPropagation();}}>{text}</span>
          {(isDesktopView(window.innerWidth) && checkCurrentTooltipMatch(surroundingInlineText +"."+(tooltipData.term ? tooltipData.term : ""))) && getTooltip(tooltipData, setVisiblePopupTooltip, history, glossaryAnalytics, 'Vehicle Page: Resources')}
        </span>
        {(!isDesktopView(window.innerWidth) && checkCurrentTooltipMatch(surroundingInlineText +"."+(tooltipData.term ? tooltipData.term : ""))) && getTooltip(tooltipData, setVisiblePopupTooltip, history, glossaryAnalytics, 'Vehicle Page: Resources')}
      </>
    );
  }

  const sideBarNestedMenuClickAnalytics = (item) => {
    analytics.subNavItemClick({
      subsection: params.model,
      page: params?.page,
      category: item?.title,
      positionTitle: 'Resources',
      modelName: params.model,
      modelYear: params.year,
    });
  }

  const sideBarPageClickAnalytics = (item, parentTitle) => {
    analytics.subNavItemClick({
      subsection: params.model,
      category: item?.title,
      page: params?.page,
      positionTitle: !parentTitle ? 'Resources' : 'Comparison',
      modelName: params.model,
      modelYear: params.year
    })
  }

  const sideBarLinkClickAnalytics = (item) => {
    analytics.subNavItemClick({
      subsection: params.model,
      page: params?.page,
      category: item?.title,
      positionTitle: 'Resources',
      modelName: params.model,
      modelYear: params.year
    })
  }

  const getResourcesPageComponent = () => {
    switch (currentResourcesPage.urlPath) {
      case "related-content":
        return (
          <RelatedContent recommendedArticles={recommendedArticles} articles={articles} model={props.model} year={props.year} />
        );
      case "incentives":
        return <LocalIncentives params={{ isCompact: true, ...props }} />;
      case "head-to-head":
        return (
          <div className="head-to-head-content-container">
            <HeadToHead
              model={props.model}
              year={props.year}
              pageData={currentResourcesPage}
              glossaryData={glossaryData}
              glossaryTerms={glossaryTerms}
              applyTooltip={applyTooltip}
            />
          </div>
        );
      case "trim-walk":
        return (
          <div className="trim-walk-content-container">
            <TrimWalk
              model={props.model}
              year={props.year}
              pageData={currentResourcesPage}
            />
          </div>
        );
      case "third-party-comparison":
        return (
          <div className="third-party-comparison-content-container">
              <ThirdPartyComparison
                model={props.model}
                year={props.year}
                pageData={currentResourcesPage}
                glossaryTerms={glossaryTerms}
                glossaryData={glossaryData}
                applyTooltip={applyTooltip}
              />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="resources-v2">
      <SideBarMenu
        menuItems={props.menuItems}
        device={props.device}
        setSelectedPage={setCurrentResourcesPage}
        selectedPage={currentResourcesPage}
        urlPrefix={`/product/${getUrlModelName(params.model)}/${params.year}/${params.page}/`}
        sideBarNestedMenuClickAnalytics={sideBarNestedMenuClickAnalytics}
        sideBarPageClickAnalytics={sideBarPageClickAnalytics}
        sideBarLinkClickAnalytics={sideBarLinkClickAnalytics}
      />
      <div className="resources-page-content">
        <Header title={currentResourcesPage?.parentTitle ? currentResourcesPage.parentTitle.toUpperCase() : currentResourcesPage?.title} subtitle={currentResourcesPage?.parentTitle ? currentResourcesPage?.title : ''} />
        {currentResourcesPage && getResourcesPageComponent()}
      </div>
    </div>
  );
};

export default VehicleResourcesV2;
