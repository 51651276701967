import { useContext, useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import { useHistory } from "react-router-dom";
import AppSettings from "../../appsettings";
import { NitroContext } from "../../components/contexts/nitroContext";
import BackButton from "../../components/molecules/BackButton";
import Badge from '../../components/molecules/Badge';
import { getXPMissionDetails } from "../../services/nitroService";
import "../../styles/css/engageXP/engageXPMissionDetail.css";
import EmptyBox from "./components/emptyBox";
import XPProgressBar from "./components/progressBar";
import XPTaskList from "./components/taskList";
import { backButtonClick, xpMissionDetailsPageLoad, faqButtonClick } from './engageXPMissionDetail.analytics';
import { postViewPageAction } from "../../services/nitroService";
import { renderRewardText, getMissionImage, renderTournamentRewards } from "./utils";

const s3Path = AppSettings.AWSImgRepo.resources;
const repeatableIcon = "en/repeatable_icon.svg";

const EngageXPMissionDetail = ({
    missionId,
    missions,
    getBlurredBadgeText,
    getColoredBadgeInfo,
    pageData,
    shouldFetchMissionDetails,
    setShouldFetchMissionDetails,
    analyticsData,
    proPointReward,
    tournament,
    isUserEligible,
    updateUserStats,
}) => {
    const history = useHistory();
    const [mission, setMission] = useState();
    const [missionDetails, setMissionDetails] = useState();
    const [isLoadingMissionDetails, setIsLoadingMissionDetails] = useState(true);
    const [coloredBadgeInfo, setColoredBadgeInfo] = useState();
    const [showColoredBadge, setShowColoredBadge] = useState(true);
    const { setNitroLoadingState } = useContext(NitroContext);
    const missionDate = missionDetails?.endTime ? new Date(missionDetails.endTime * 1000) : null;
    const currentDate = new Date();
    const isMissionExpired = missionDate && missionDate < currentDate;
    const shouldDisplayExpiration = !!missionDate;
    const clickAnalytics = analyticsData?.clickAnalytics;
    const analyticsPage = tournament ? missionDetails?.displayName || missionDetails?.name : "Engage XP";
    const analyticsCategory = "Mission Detail";


    async function fetchMissionDetails(id) {
        const missionDetailsResult = await getXPMissionDetails(id);
        setMissionDetails(missionDetailsResult);
        setNitroLoadingState(false);
        setIsLoadingMissionDetails(false);
    }
    
    useEffect(() => {
        fetchMissionDetails(missionId);
    }, [missionId]);

    useEffect(() => {
        if (shouldFetchMissionDetails) {
            fetchMissionDetails(missionId);
            setShouldFetchMissionDetails(false);
        }
    }, [shouldFetchMissionDetails]);

    useEffect(() => {
        if (!missions.length) {
            return;
        }

        const missionMatch = missions.find((mission) => mission.id == missionId);
        if (!missionMatch || missionMatch.locked.status || missionMatch.eligibilityStatus === 'NOT_ELIGIBLE') {
            if (window.location.href.includes('tournaments')) {
                history.push('/engage-xp/tournaments');
            } else {
                history.push('/engage-xp');
            }
        }
        
        const labelMatch = getColoredBadgeInfo(missionMatch?.labels);
        setMission(missionMatch);
        setColoredBadgeInfo(labelMatch);
        setShowColoredBadge(false);
    }, [missionId, missions]);

    useEffect(() => {
        if (analyticsData && (missionDetails?.displayName || missionDetails?.name)) {
            const name = missionDetails?.displayName || missionDetails?.name;
            postViewPageAction(window.location.href);
            xpMissionDetailsPageLoad(analyticsData.pageLoad?.missionDetails, name);
        }
    }, [analyticsData, missionDetails]);


    const handleBackClick = () => {
        if (analyticsData) {
            backButtonClick(clickAnalytics, analyticsPage, analyticsCategory);
        }

        if (!tournament) {
            history.push("/engage-xp/missions");
        } else {
            updateUserStats();
            history.push("/engage-xp/tournaments");
        }
        
    }

    const faqClick = () => {
        if (analyticsData) {
            faqButtonClick(clickAnalytics, analyticsPage, analyticsCategory);
        }
        history.push("/engage-xp/faqs");
    }
    const MissionDetailsFooter = ({ isRepeatable, isCompleted }) => {
        const visibility = isCompleted ? "visible" : "hidden";
        const missionCompletedText = isRepeatable
            ? "MISSION COMPLETED! PLAY AGAIN."
            : "MISSION COMPLETED!";
        return (
            <div className="mission-details-footer">
                <div className="mission-details-completed-text" style={{ visibility: visibility }}>
                    {missionCompletedText}
                </div>
                <div className="mission-details-faqs">
                    {"Have questions? "}
                    <span className="mission-details-faqs-link" onClick={faqClick}>
                        {"Check out FAQs."}
                    </span>
                </div>
            </div>
        );
    };

    return <div className="engageXPMissionDetail">
        <div className="engageXPMissionDetail-header">
            <BackButton onClick={handleBackClick} />
            <h1>{missionDetails?.displayName || missionDetails?.name}</h1>
        </div>
        <div className="engageXPMissionDetail-content">
            <div className="engageXPMissionDetail-content-side">
                {isLoadingMissionDetails ? (
                    <Skeleton height={330} />
                ) : (
                    <>
                        <div className='mission-card-body'>
                            <div className='mission-card-hero' style={{ backgroundImage: getMissionImage(missionDetails) }}>
                                {getBlurredBadgeText(mission?.labels) && <Badge type="blurred">{getBlurredBadgeText(mission?.labels)}</Badge>}
                            </div>
                            <div className="mission-card-content">
                                <div>
                                    <div className='mission-infos'>
                                        {coloredBadgeInfo ? (
                                            <Badge type="colored" color={coloredBadgeInfo.color}>
                                                {coloredBadgeInfo.name}
                                            </Badge>
                                        ) : (
                                            showColoredBadge ? <Skeleton width={64} height={33} /> : <EmptyBox width={64} height={33} />
                                        )}
                                        {shouldDisplayExpiration ? (
                                            <p className='mission-expiration'>
                                                {isMissionExpired ? "Expired" : `Exp. ${missionDate.toLocaleString('default', { month: 'short' })} ${missionDate.getDate()}`}
                                            </p>
                                        ) : null}
                                    </div>
                                    {tournament && isUserEligible ?
                                        renderTournamentRewards(missionDetails?.rewards, proPointReward)
                                    :
                                        <h1>{renderRewardText(missionDetails?.rewards)}</h1>
                                    }
                                    
                                </div>
                                {missionDetails?.repeatable && <div className='mission-repeatable'>
                                    <img src={s3Path + repeatableIcon} />
                                    <span><b>Repeatable</b> (Completed {missionDetails?.completionCount} {missionDetails?.completionCount !== 1 ? "times" : "time"})</span>
                                </div>}
                            </div>
                        </div>
                        <p className="mission-description">{missionDetails?.description}</p>
                    </>

                )}
            </div>
            <div className="engageXPMissionDetail-content-side">
                {isLoadingMissionDetails ? (
                    <Skeleton height={330} />
                ) : (
                    <>
                        <h4>{pageData.missionDetails.completionText ?? ''}</h4>
                        <XPProgressBar variant="missionPage" leftLabel={pageData.missionDetails.progressBarTitle ?? ''} percent={missionDetails?.percentComplete} gradient={tournament && ['#2DADFE', '#0C56C9']} />
                        <XPTaskList 
                            missionId={missionId} 
                            tasks={missionDetails?.rules} 
                            reload={() => fetchMissionDetails(missionId)} 
                            pageData={pageData.missionDetails}
                            missionName={missionDetails?.name || missionDetails?.displayName} 
                            analyticsData={analyticsData}
                            tournament={tournament}
                        />
                        <MissionDetailsFooter isRepeatable={missionDetails?.repeatable}  analyticsData={analyticsData} 
                        isCompleted={!!missionDetails?.completionCount > 0} />
                    </>
                )}
            </div>
        </div>
    </div>
}

export default EngageXPMissionDetail;
