import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CheckRadio from "../../../components/atoms/CheckRadio";
import { NitroContext } from '../../../components/contexts/nitroContext';
import { selfReportAction } from '../../../services/nitroService';
import "../../../styles/css/xp-task-list.css";
import { taskClick } from '../engageXPMissionDetail.analytics';

const XPTaskList = (props) => {
  const history = useHistory();
  const { setNitroLoadingState } = useContext(NitroContext);
  const getActionButtonText = (task) => {
    const titleInLower = task?.description?.toLowerCase();

    for (const config of props.pageData.actionTextMapping) {
      const { condition, action } = config;
      if (titleInLower?.includes(condition.toLowerCase())) {
        return action;
      }
    }

    return props.pageData.defaultActionLinkText ?? 'Start Now';
  };

  const handleClick = async (task) => {
    if (!task.enabled) {
      return;
    }

    if (task.goalType === 'selfReporting') {
      await selfReportAction(props.missionId, task.id);
    }

    const { actionUrl } = task;
    if (!actionUrl) {
      props.reload();
      return;
    }

    const quizRegex = /\/(?:missions|tournaments)\/\d+\/\d+/;
    const internalRegex = /engage\.toyota\.com|localhost|^\//;

    if (props?.analyticsData){
      let clickAnalytics = props?.analyticsData?.clickAnalytics;
      const contentTitle = task?.description + ' - ' + getActionButtonText(task);

      if (props?.tournament) {
        clickAnalytics.page = props?.missionName;
        clickAnalytics.label = getActionButtonText(task);
      }

      taskClick(clickAnalytics, contentTitle);
    }

    if (quizRegex.test(actionUrl)) {
      setNitroLoadingState(true);
    } else if (internalRegex.test(actionUrl)) {
      // Back-to-mission widget settings
      sessionStorage.setItem('showBackToMission', 'true');
      sessionStorage.setItem('missionDetailsPage', window.location.pathname);
      sessionStorage.setItem('missionDestinationPage', actionUrl);
      sessionStorage.setItem('missionName', props?.missionName);
    }

    if (actionUrl.startsWith('/')) {
      history.push(actionUrl);
    } else if (internalRegex.test(actionUrl)) {
      window.location.href = actionUrl;
    } else {
      window.open(actionUrl);
    }
  };

  const updatedTasks = props?.tasks?.map((task, index) => ({
    ...task,
    enabled: index === 0 || props.tasks[index - 1]?.completed || task.completed,
  }));

  return (
    <ul className="xp-task-list-wrapper">
      {updatedTasks?.map((task, index) => (
        <li key={index}>
          <div>
            <CheckRadio checked={task?.completed} />
            <span className="xp-task-list-item-title">{task?.description}</span>
          </div>
          <a
            onClick={() => handleClick(task)}
            className={`xp-task-list-item-action-button ${task.enabled ? '' : 'disabled'}`}
          >
            {getActionButtonText(task)}
          </a>
        </li>
      ))}
    </ul>
  );
}

export default XPTaskList;
