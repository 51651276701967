import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const profilePagePageLoad = (pageLoad) => {
  if(pageLoad) {
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: pageLoad.page,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
  }
};


// Spin QR code click
export const scanQRCodeClick = (clickAnalytics) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Profile Page",
      label: "Spin QR code button click"
    });
  }
};

// Close QR code modal click
export const closeQRCodeModalClick = (clickAnalytics) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Profile Page",
      label: "Spin Id close modal"
    });
  }
};


// Add to Apple wallet click
export const addToAppleWalletClick = (clickAnalytics) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Profile Page",
      label: "Add to Apple Wallet Button Click"
    });
  }
};


// Add to Google wallet click
export const addToGoogleWalletClick = (clickAnalytics) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Profile Page",
      label: "Add to Google Wallet Button Click"
    });
  }
};

export const privacyNoticeClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: '28.2',
      app: 'eShow',
      container: "Profile",
      category: "Profile Page",
      label: label
    });
  }
};
export const privacyChoicesClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: '28.2',
      app: 'eShow',
      container: "Profile",
      category: "Profile Page",
      label: label
    });
  }
};