import { FormattedMessage } from 'react-intl';
import { getProfileImageUrl } from '../utils/profileImageUrl';
import { getLocale } from '../../services/languageTranslationService';
import '../../styles/css/activityCommentThread.css';
import React, { useEffect, useState } from 'react';
import appsettings from '../../appsettings';

const CommentTextArea = (props) => {

  const userHasCommentPermissions = () => localStorage.spin_id && localStorage.isGuest !== 'true' && !localStorage.disableCommenting;
  const [pictureUrl, setPictureUrl] = useState(null);
  
  useEffect(() => {
    const fetchImages = async () => {
       const imageUrl = await getProfileImageUrl();
       setPictureUrl(imageUrl);
    }
    fetchImages();

  }, []);

  return userHasCommentPermissions() ? (
    <div className="add-comment">
      <div className='add-comment-container'>
        <div className="user-profile">
        {pictureUrl ? <img alt="" src={`data:image;base64, ${pictureUrl}`} className="user-img"/> : <img className="user-img" src={appsettings.AWSImgRepo.resources + `en/default.svg`}/>}
        </div>
        <div className="text-box-container">
          <textarea value={props.value} className="text-box" placeholder={getLocale() === 'en' ? 'Share your thoughts' : 'Comparta sus opiniones'} onChange={props.onChange}></textarea>
        </div>
      </div>
      <div className='buttons-container-right'>
        <div className="active-comment-button-container">
          <div className="active-button-options">
              {props.onCancel && <div className='view-button text-upper' onClick={props.onCancel}><FormattedMessage id="global.button.cancel" /></div>}
              <div className='view-button text-upper' onClick={props.onSubmit}><FormattedMessage id="global.button.submit" /></div>
          </div>
        </div>
      </div>
      <p className="error-text">{props.component.state.commentError ? <FormattedMessage id="article.commentLengthError" /> : ''}</p>
    </div>
  ) : (
    <div className="comment-error">
      {localStorage.isGuest ? <FormattedMessage id="article.guestAccountMessage" /> : <FormattedMessage id="article.commentError" />}
    </div>
  )  
}

export default CommentTextArea;
