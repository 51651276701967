import React, { useEffect, useState, useRef } from 'react';
import '../../styles/css/tooltipV2.css';
import AppSettings from '../../appsettings.js';

// REQUIRED props (I'll crash the page if you don't include this):
// closeTooltip: function(), (this should be a function that sets a state variable to null **in the parent class** since the parent needs to track the state of the tooltip, the tooltip won't track the state of itself)
// - the visibleTooltip/setVisibeTooltip state variable/function needs to be at the appropriate parent level to trigger a re-render when the state is changed

// RECOMMENDED props (the page will *technically* render but the tooltip will look goofy without the following):
// title: String, (will be bold, black text at the top)
// subtitle: String, (will be italicized, black text under the title) 
// description: String (will be grey text at the bottom)

// ANALYTICS props: (the functions and data will need to be defined in the parent component to allow for multiple tooltips with different analytics requirements to co-exist)
// analyticsOpenTooltip: Function(), 
// analyticsCloseTooltip: Function(), 
// analyticsMoreClick: Function(ctaLabel), (passing in the label, meaning the function you write in the parent class will need to accept this)

// OPTIONAL props:
// moreLinkFunction: Function(), (the MORE clickable text will only render if this exists, having it be a function rather than a URL allows for more flexibility but it just means you have to define the behavior in the parent component)
// moreLinkCTA: String, (In case you want it to say something other that "MORE")


export const TooltipV2 = (props) => {
    useEffect(() => {
        props.analyticsOpenTooltip ? props.analyticsOpenTooltip() : console.log('Tooltip is missing analytics function when opened');
    }, []);

    const closeButtonClick = () => {
        props.closeTooltip();
        props.analyticsCloseTooltip ? props.analyticsCloseTooltip() : console.log('Tooltip is missing analytics function when closed');
    };

    const moreButtonClick = () => {
        props.analyticsMoreClick ? props.analyticsMoreClick() : console.log('Tooltip is missing analytics function when MORE is clicked');
        props.moreLinkFunction();
    };

    return (
        <div className="tooltip-container">
            <div className="tooltipV2">
                {props.closeTooltip ? 
                    <div className="tooltipCloseButton" onClick={(event)=>{closeButtonClick(); event.stopPropagation();}}>
                        <img className="closeIcon" src={AppSettings.AWSImgRepo.resources + 'en/' + 'icon_close_black.svg'} /> 
                    </div> : null
                }
                <div className="tooltipContent">
                    {props.title && <div className="tooltip-title">{props.title}</div>}
                    {props.subtitle && <div className="tooltip-subtitle">{props.subtitle}</div>}
                    {props.description && <div className="tooltip-descriptionBody">
                        <span className="tooltip-descriptionText">{props.description}</span>
                        {props.moreLinkFunction && <a className="tooltip-moreLink" onClick={()=>moreButtonClick()}> {"MORE"}</a>}
                    </div>}
                </div>
            </div>
        </div>

    );
};