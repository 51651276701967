import React from 'react';
import AppSettings from '../../appsettings';
import "../../styles/css/accordionLegend.css";

const AccordionLegend = (props) => {
    if (props.type === "sop") {
        return (
            <div className={`accordion-legend sop ${props.left ? "left" : ""}`}>
                <div className='item'>
                    <img
                        className='standard-icon'
                        src={AppSettings.AWSImgRepo.resources + `en/standard.png`}
                        alt=''
                    />
                    <span className='item-label'>Standard</span>
                </div>
                <div className='item'>
                    <img
                        className='option-icon'
                        src={AppSettings.AWSImgRepo.resources + `en/option.png`}
                        alt=''
                    />
                    <span className='item-label'>Options</span>
                </div>
                <div className='item'>
                    <img
                        className='package-icon'
                        src={AppSettings.AWSImgRepo.resources + `en/package.png`}
                        alt=''
                    />
                    <span className='item-label'>Packages</span>
                </div>

            </div>
        )
    } else if (props.type === "advantage") {
        return (
            <div className='accordion-legend advantage'>
                <img
                    className='advantage-diamond-icon'
                    src={AppSettings.AWSImgRepo.resources + `en/advantage-diamond.svg`}
                    alt=''
                />
                <div className='advantage-text'>
                    <span className='title'>Advantage</span>
                    <div className='details'>Values estimated at time of publication and subject to change without notice</div>
                </div>
            </div>
        )
    } else if (props.type === "availability") {
        return (
        <div className='accordion-legend availability'>
            <div className='standard-item'>
                <img
                    className='standard-icon'
                    src={AppSettings.AWSImgRepo.resources + `en/standard.png`}
                    alt=''
                />
                <span className='item-label'>Standard</span>
            </div>
            <div className='accordion-legend-item-available'>
                <div className="accordion-legend-available-indication">A</div>
                <span className='item-label'>Available</span>
            </div>
            <div className='accordion-legend-item-unavailable'>
                <div className="accordion-legend-not-available-indication">—</div>
                <span className='item-label'>Not Available</span>
            </div>
        </div>
        )
    }

    else return <></>;
}

export default AccordionLegend;