import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UserManagerContext } from '../contexts/userManagerContext';
import authLogger from '../../services/authLoggingService';
import { deleteCookie } from '../../screens/utils/cookie';
import ScrollingModal from '../molecules/scrollingModal';

const OIDCLogout = () => {
  const history = useHistory();
  const location = useLocation();
  const { userManager } = useContext(UserManagerContext);
  const [modalVisible, setModalVisible] = useState(false);

  try {
    useEffect(() => {
      const signOut = async () => {
        if (!userManager) {
          return;
        }
        const params = new URLSearchParams(location.search);
        const isAuto = params.get('source') === 'auto';
        const isIdle = params.get('status') === 'idle';
        const previousUrl = sessionStorage.getItem("desiredNav");

        if (!isAuto) {
          deleteCookie('engageCorpUser', true);
        }

        localStorage.clear();
        sessionStorage.clear();
        deleteCookie('iPlanetDirectoryPro', true);

        if (previousUrl) {
          sessionStorage.setItem("desiredNav", previousUrl);
        }

        if (isIdle) {
          setModalVisible(true);
          return;
        }

        await handleRedirect();
      };
      signOut();
    }, [userManager]);
  } catch (error) {
    authLogger(localStorage.getItem('spin_id'), `Error Logging Out ${error}`);
    console.log(error);
  }

  const handleRedirect = async () => {
    try {
      const user = await userManager.getUser();
      if (user?.id_token) {
        await userManager.signoutRedirect({ id_token_hint: user.id_token });
      }
    } catch (error) {
      console.error('Error during signOut:', error);
    }
    setModalVisible(false);
    history.push('/login');
  };

  return (
    <div>
      {!modalVisible && <div>Redirecting to logout page...</div>}
      <ScrollingModal
        visible={modalVisible}
        closeModal={handleRedirect}
        title="You have been logged out."
        modalClassName="logout-modal"
      >
        <p>Your session has expired. Please log in again to continue using Engage.</p>
      </ScrollingModal>
    </div>
  );
};

export default OIDCLogout;
