import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

/*

required props
    ** either value OR profile (if value is provided, profile is ignored) **
    value
        string
        most common use is a url
    profile
        object with keys for lastName, firstName, spinId, dealerCode, dealerName, email, jobCode, & jobTitle
        converted to qr code value using generateQRValueFromProfile() below

optional props
    size
        number
        in px, sets width and height of svg generated
        defaults to 110
    includeMargin
        boolean
        includes bgColor margin around qrcode @ width provided by qrcode.react (~1/6 of size)
        defaults to true
    class
        string
        added to div wrapper
        defaults to 'qr-code' (plus 'profile-qr-code' if value generated from profile)
    bgColor
        string (CSS Color)
        background color
        defaults to white
    fgColor
        string (CSS Color)
        foreground color
        defaults to black
    className
        string
        passed through to QRCodeSVG element
    style
        CSS style object
        passed through to QRCodeSVG element

 */

function QRCode(props) {
    let value;
    const classes = ['qr-code', props.class];

    if (props.profile) {
        classes.push('profile-qr-code')
    }
    
    function generateQRValueFromProfile(profile) {
        const delimiter = "\t";
        var credentials = "";
        credentials += profile.lastName + delimiter;
        credentials += profile.firstName + delimiter;
        credentials += profile.spinId + delimiter;
        credentials += profile.dealerCode + delimiter;
        credentials += profile.dealerName + delimiter;
        credentials += profile.email + delimiter;
        credentials += profile.jobCode + delimiter;
        credentials += profile.jobTitle + delimiter;
        return credentials;
    }
    
    return (
        <div className={classes.join(' ')}>
            <QRCodeSVG
                value={props.value ? props.value : generateQRValueFromProfile(props.profile)}
                size={props.size || 110}
                bgColor={props.bgColor}
                fgColor={props.fgColor}
                level={props.level}
                includeMargin={(props.includeMargin == undefined) ? true : props.includeMargin}
                className={props.className}
                style={props.style}
            />
        </div>
    );
}

export default QRCode;