import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { selectTrim } from "../../../screens/vehicleDetails/vehicleDetails.analytics.js";
import "../../../styles/scss/vp-header/vp-trim-selector.scss";

const TrimSelector = ({ trimsList, selectedTrim, setSelectedTrim, analyticsData }) => {
    const [isLoading, setIsLoading] = useState(true);

    // Update the isLoading state based on the trimsList
    useEffect(() => {
        if (trimsList && trimsList.length > 0) {
            setIsLoading(false);
        }
    }, [trimsList]);

    return (
        <div className="trim-selector-container">
            {isLoading ? (
                 <div className="trim-selector-skeleton-container">
                 <Skeleton
                   width={80}
                   height={20}
                   count={5}
                 />
               </div>
            ) : (
                trimsList.map((trim) => {
                    const isSelected = trim.name === selectedTrim.name
                    return (
                        <div
                            key={trim.name}
                            className={`trim-item ${isSelected ? "trim-item-selected" : ""}`}
                            onClick={() => {setSelectedTrim(trim); sendAnalytics(analyticsData, trim.name)}}
                            role="button"
                        >
                            <span className={isSelected ? "selected" : ""}>{trim.name}</span>
                        </div>
                    )
                })
            )}
        </div>
    )
}

function sendAnalytics(analyticsData, selectedTrim){
    selectTrim({
        page: analyticsData?.page,
        contentTitle: selectedTrim,
        modelName: analyticsData?.modelName,
        modelYear: analyticsData?.modelYear
    })
}

export default TrimSelector;
