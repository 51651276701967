import React from 'react';

import adjustStringForUrl from '../utils/adjustStringForUrl';
import { getFormattedName, getUrlModelName } from '../utils/vehicleName';
import { capFirstAndRestLowerCase } from '../utils/capitalize';
import Tooltip from '../../components/molecules/Tooltip';
import '../../styles/css/resultHelperFunctions.css';
import AppSettings from '../../appsettings.js';
import DropdownV2 from '../../components/molecules/dropdownV2';
import { expandCollapseAnswers, enhancedSearchClick } from './searchBar.analytics';
import applyMarkdown from '../utils/applyMarkdown';
import { convertNameToSlug } from '../VPRouter/VPUtils.js'

const supraSafetyLink = '/articles/12_9_2019_supra_safety_summary';

function getBaseVehicleLink(result) {
  return `/product/${getUrlModelName(result.displayModelName)}/${result.year}`;
}

function getTCOPLink(result) {
  return `${getBaseVehicleLink(result)}/tcop`;
}

function getTCOPLinkForColors(result, state) {
  const selectedTrim = state.trims[state.trims[state.selectedTrimValue] ? state.selectedTrimValue : 0].trim;
  const selectedColor = state.selectedColor ? state.selectedColor.paintCodeName : '';
  return `${getBaseVehicleLink(result)}/tcop?trim=${selectedTrim}&color=${selectedColor}#colors`;
}

function getTCOPLinkForPackages(result, state) {
  let link = `${getBaseVehicleLink(result)}/tcop`;
  link += `?trim=${state.selectedTrimValue ? state.selectedTrimValue : state.trims[0].trim}#packages`;
  return link;
}

const formatFeatSpecLink = ({ type, displayGroupName, descriptionToShow, topCategoryName }, engine = null, trim = null) => {
  let url = type === 'spec' ? 'details/specifications' : 'details';
  const featurePathKey = topCategoryName || displayGroupName;
  const featSpect = topCategoryName ? displayGroupName : descriptionToShow;

  url += type == 'spec' 
    ? `?type=${convertNameToSlug(displayGroupName)}` 
    : `/${convertNameToSlug(featurePathKey)}-features?`;

  if (descriptionToShow) url += `&featSpec=${convertNameToSlug(featSpect)}`
  if (trim) url += `&trim=${trim}`
  if (engine) url += `&engine=${engine}`

  return url;
}

function getFeatureLink(result) {
  const featureGroupForURL = adjustStringForUrl(result.topCategoryName);
  const featureTitleForURL = adjustStringForUrl(result.displayGroupName);
  const baseURL = getBaseVehicleLink(result);

  return parseInt(result.year) < 2023 
    ? `${baseURL}/featuresAndSpecs?featureSpec=${featureGroupForURL}-${featureTitleForURL}`
    : `${baseURL}/${formatFeatSpecLink(result)}`
}

function getGroupFeatureLink(result, state) {
  const featureGroupForURL = adjustStringForUrl(result.displayGroupName);
  const featureTitleForURL = adjustStringForUrl(result.descriptionToShow);
  const baseURL = getBaseVehicleLink(result);
  const engine = state.selectedEngineValue ? state.selectedEngineValue : state.filteredEngines[0].engine;
  const trim = state.selectedTrimIndex ? state.filteredTrims[state.selectedTrimIndex].trim : state.filteredTrims[0].trim;

  return parseInt(result.year) < 2023
    ? `${baseURL}/featuresAndSpecs?engine=${engine}&trim=${trim}&featureSpec=${featureGroupForURL}-${featureTitleForURL}`
    : `${baseURL}/${formatFeatSpecLink(result, engine, trim)}`
}

function getSpecLink(result, state) {
  const specGroupForURL = adjustStringForUrl(result.displayGroupName);
  const baseURL = getBaseVehicleLink(result);
  const engine = state.selectedEngineValue;
  const trim = state.filteredTrims[state.selectedTrimIndex].trim;

  return parseInt(result.year) < 2023
    ? `${baseURL}/featuresAndSpecs?engine=${engine}&trim=${trim}&featureSpec=${specGroupForURL}`
    : `${baseURL}/${formatFeatSpecLink(result, engine, trim)}`
}

function getMSRPLink(result, state) {
  return getTCOPLink(result);
}

function getModelCodeLink(result, state) {
  const baseURL = getBaseVehicleLink(result);
  return parseInt(result.year) < 2023
    ? `${baseURL}/featuresAndSpecs?engine=${state.engineName}&trim=${state.trimName}`
    : `${baseURL}/${formatFeatSpecLink(result, state.engineName, state.trimName)}`
}

function getDisplayResultType(result) {
  let displayType;

  switch (result.type) {
    case 'feature':
    case 'groupFeature':
      displayType = 'Feature';
      break;
    case 'spec':
    case 'tss':
      displayType = 'Specification';
      break;
    case 'msrp':
    case 'colorAvailability':
    case 'colorByTrim':
    case 'modelCode':
      displayType = 'Trims';
      break;
    case 'packageByTrim':
      displayType = 'Package';
      break;
    default:
      displayType = 'Result';
  }
  return displayType;
}

function generateResultLinkout(result, state) {
  let link;
  if (result.model === 'gr supra' && result.group.includes('Safety')) {
    link = supraSafetyLink;
  } else {
    switch (result.type) {
      case 'feature':
        link = getFeatureLink(result);
        break;
      case 'groupFeature':
        link = getGroupFeatureLink(result, state);
        break;
      case 'spec':
        link = (result.model === 'gr supra' && result.group === 'Safety') ? supraSafetyLink : getSpecLink(result, state);
        break;
      case 'msrp':
        link = getMSRPLink(result, state);
        break;
      case 'tss':
        link = getBaseVehicleLink(result); // should add learn more to tss item
        break;
      case 'colorAvailability':
        link = getTCOPLink(result);
        break;
      case 'colorByTrim':
        link = getTCOPLinkForColors(result, state);
        break;
      case 'packageByTrim':
        link = getTCOPLinkForPackages(result, state);
        break;
      case 'modelCode':
        link = getModelCodeLink(result, state);
        break;
      default:
        link = getBaseVehicleLink(result);
    }
  }
  return link;
}

export function filterTrims(itemValues, engine) {
  return itemValues.filter(trim => trim.engine === engine);
}

export function getAllPackages(trims) {
  const trimPackagesAsKeys = []
  trims.forEach((trim) => {
    trim.values.forEach((item) => {
      if(item in trimPackagesAsKeys){
        return
      }
      else{
        trimPackagesAsKeys[item.title] = item.code
      }
    });  
  });
  const trimPackages = Object.keys(trimPackagesAsKeys)
  return trimPackagesAsKeys
}

export function filterColors(itemValues, trim) {
  let colors = [];
  itemValues.forEach((value, key) => {
    if (key === trim) {
      colors = value;
    }
  });
  return colors;
}

export function getEngineOptions(itemValues) {
  const filteredEngines = [...new Set(itemValues.map(x => x.engine))];
  const engineValue = filteredEngines ? filteredEngines.map((engineItem, index) => (
    engineItem
  )) : null;

  return engineValue;
}

export function getTrimOptions(filteredTrims) {
  const trimValue = filteredTrims ? filteredTrims.map((trimItem, index) => (
    {
      label: trimItem.trim,
      value: index,
    }
  )) : null;

  return trimValue;
}
export function getPackageOptions(packages) {
  const packagesValue =  packages.map((packages, index) => (
    {
      label: packages,
      value: index,
    }
  ));

  return packagesValue;
}

function searchLowerCase(baseString, searchTerm) {
  return baseString.toLowerCase().search(searchTerm) !== -1;
}

function generateTooltipMessage(result) {
  const groupName = result.displayGroupName || result.group;
  const { type } = result;
  const tooltipOptions = ['safety', 'msrp', 'package', 'color', 'feature', 'groupeFeature', 'spec', 'modelcode'];

  let messageArr = tooltipOptions.filter(searchTerm => searchLowerCase(groupName, searchTerm));
  if (!messageArr.length) {
    messageArr = tooltipOptions.filter(searchTerm => searchLowerCase(type, searchTerm));
  }

  if (messageArr[0] === 'safety' && result.model === 'gr supra') {
    return 'tooltip.search.safety.supra';
  }

  return `tooltip.search.${messageArr[0]}`;
}


export function generateResultHeader(result, state) {
  const link = generateResultLinkout(result, state);
  const displayType = getDisplayResultType(result);
  const groupName = result.displayGroupName || result.group;
  const tooltipMessage = generateTooltipMessage(result);
  const tooltipId = `result ${groupName}`;
  return (
    <div className="item-header">
      <a target="_blank" href={link} id="search-model-year" className="vehicle-link" data-tip data-for={tooltipId}>
        <b>{result.type === 'modelCode' ? '' : result.year}  {applyMarkdown(getFormattedName(result.displayModelName))}  </b>
      </a> | {displayType} | {capFirstAndRestLowerCase(groupName)}
      <Tooltip id={tooltipId} message={tooltipMessage} />
    </div>
  );
}

export function generateLearnMoreButton(result, state, clickAnalytics, searchParam, numberOfSearchResults) {
  const link = generateResultLinkout(result, state);
  const groupName = result.displayGroupName || result.group;
  const tooltipMessage = generateTooltipMessage(result);
  const tooltipId = `learnMore ${groupName}`;
  const cta = "See More";
  return (
    <div className="learnMoreContainer">
      <div className="learn-more-margin"></div>
      <a target="_blank" onClick={() => seeMoreClickAnalytics(clickAnalytics, cta, searchParam, numberOfSearchResults, result)} href={link} className="learn-more-button" data-tip data-for={tooltipId}>
        {cta}
      </a>
      <Tooltip id={tooltipId} message={tooltipMessage} />
    </div>
  );
}

export function generateOptionsDropdown(options, onChangeFunction, placeholder, highlightItem) {
  const imagePath = AppSettings.AWSImgRepo.resources + 'en/';
  const dropdownClosedArrow = <img className="options-dropdown-arrow" src={imagePath + "Down_Arrow_Black.png"} />;
  const dropdownOpenArrow = <img className="options-dropdown-arrow-upside-down" src={imagePath + "Down_Arrow_Black.png"} />;
  const defaultValue = options.length === 1 ? options[0] : placeholder;
  return (
    <div className="options-dropdown">
      <DropdownV2
        arrowClosed={dropdownClosedArrow}
        arrowOpen={dropdownOpenArrow}
        defaultValue={defaultValue}
        // disabled={noEBrochureYears}
        menuClassName={"options-dropdown-menu"}
        options={options}
        onValueChange={value => onChangeFunction(value.value)}
        placeholderClassName={`options-dropdown-placeholder`}
      />
    </div>

  )
}

export function getQuickAnswerType(type) {
  switch (type) {
    case 'feature':
      return 'Features';
    case 'groupFeature':
      return 'Group Features';
    case 'spec':
      return 'Specs';
    case 'msrp':
      return 'Pricing';
    case 'tss':
      return 'TSS';
    case 'colorAvailability':
    case 'colorByTrim':
      return 'Exterior Colors';
    case 'packageByTrim':
      return 'Packages';
    case 'modelCode':
      return 'Model Codes';
    default:
      return type;
  }
}

export function toggleVisibilityAnalytics(toggle, isKnowledgePane, props) {
  if (props.clickAnalytics) {
    const enhancedSearch = props.clickAnalytics.enhancedSearch;
    const category = isKnowledgePane ? enhancedSearch.categoryQuickSpecs : enhancedSearch.categoryRichAnswers;
    const positionTitle = `${props.component.state.currentVehicle.modelYear} ${props.component.state.currentVehicle.model}`;
    const contentTitle = `${enhancedSearch.contentTitleExplore} ${props.component.state.currentVehicle.model}`;
    const label = `${enhancedSearch.labelTile} ${toggle ? "Expand" : "Collapse"}`;
    expandCollapseAnswers(
      props.clickAnalytics, 
      category,
      positionTitle, 
      contentTitle, 
      label, 
      props.component.state.currentVehicle.modelYear, 
      props.component.state.currentVehicle.model,
      props.keyword,
      props.numberOfSearchResults,
    );
  }
}

function seeMoreClickAnalytics(clickAnalytics, cta, searchParam, totalResults, result) {
  if (clickAnalytics && result && cta && searchParam && totalResults) {
    const positionTitle = `${result.year} ${result.model}`;
    const label = `${getQuickAnswerType(result.type)}:${cta}`;
    enhancedSearchClick(
      clickAnalytics,
      positionTitle,
      result.descriptionToShow,
      label,
      result.year,
      result.model,
      searchParam,
      totalResults,
    );
  }
}

export function optionsDropdownAnalytics(value, placeholder, highlightItem, clickAnalytics, keyword, numberOfSearchResults) {
  if (clickAnalytics) {
    const enhancedSearch = clickAnalytics.enhancedSearch;
    const positionTitle = `${highlightItem.year} ${highlightItem.model}`;
    const contentTitle = `${highlightItem.descriptionToShow}:${value}`;
    const label = `${getQuickAnswerType(highlightItem.type)}:${placeholder}${enhancedSearch.labelSpecChange}`
    enhancedSearchClick(
      clickAnalytics,
      positionTitle,
      contentTitle,
      label,
      highlightItem.year,
      highlightItem.model,
      keyword,
      numberOfSearchResults,
    );
  }
}

export function colorPickerAnalytics(value, highlightItem, clickAnalytics, keyword, numberOfSearchResults) {
  const enhancedSearch = clickAnalytics.enhancedSearch;
  const positionTitle = `${highlightItem.year} ${highlightItem.model}`;
  const label = `${getQuickAnswerType(highlightItem.type)}:${enhancedSearch.labelColorSelect}`;
  if (clickAnalytics) {
    enhancedSearchClick(
      clickAnalytics,
      positionTitle,
      value.paintCodeName,
      label,
      highlightItem.year,
      highlightItem.model,
      keyword,
      numberOfSearchResults,
    );
  }
}

export function singleSlideTab(title, imagePath, disclaimerFunction) {
  return (
    <div className='singleSlideTab'>
      <div className='tabItem'>
        <div className='tabName'>
          <span>
            {title === 'MSRP' ? 'Base MSRP' : title}
            {title === 'MSRP' ? <sup className="disclaimer-link" onClick={() => disclaimerFunction()}>&dagger;</sup> : null}
          </span>
        </div>
        <div className="tabArrow"><img src={imagePath + "Search_Path_Icon.png"} /></div>
      </div>
    </div>
  )
}

export function checkTruncated(ref) {
  if (ref && ref.current) {
    return ref.current.offsetHeight < ref.current.scrollHeight;
  } else {
    return false;
  }
}

export const HeaderTooltip = (props) => {
  return (
    <div onMouseLeave={() => props.leaveFunction(false)} className={"header-tooltip"}>{props.desc}</div>
  );
}
