import React, {useState, useEffect} from 'react';
import AppSettings from '../../../appsettings';
import DropdownV2 from '../../../components/molecules/dropdownV2';
import { isDesktopView, isTabletView, isMobileView } from '../../utils/getScreenSize';
import '../../../styles/css/trimComparisonComponent.css';
import { selectDropdown, selectComparison } from './featuresSpecs.analytics';

const TrimComparisonComponent = (props) => {
    const {
        comparisonActive,
        setComparisonActive,
        comparisonYears,
        s3PathName,
        selectedTrim,
        secondSelectedTrim,
        setSecondSelectedTrim,
        secondSelectedYear,
        setSecondSelectedYear,
        secondTrimsOptions,
        year,
        analyticsData
      } = props;

    const [firstSelectedImage, setFirstSelectedImage] = useState('');
    const [secondSelectedImage, setSecondSelectedImage] = useState('');

    useEffect(() => {
        setFirstSelectedImage(`${AppSettings.AWSImgRepo.vehicle}${props.year}/${s3PathName}/en/${selectedTrim?.trimImageFilename}`)
    }, [props.s3PathName, props.year, props.selectedTrim])

    useEffect(() => {
        setSecondSelectedImage(`${AppSettings.AWSImgRepo.vehicle}${secondSelectedYear}/${s3PathName}/en/${secondSelectedTrim?.trimImageFilename}`)
    }, [props.s3PathName, props.year, props.secondSelectedTrim])

    const CloseButton = () => (
        <div className='trim-comparison-close' onClick={() => {
            setComparisonActive(false);
            setSecondSelectedTrim(secondTrimsOptions[0]);
            setSecondSelectedYear(comparisonYears[0]);
            sendComparisonAnalytics(false);
        }}>
            <img src={AppSettings.AWSImgRepo.resources + 'en/minus-circle-black.svg'} />
        </div>
    );

    function sendComparisonAnalytics(isOpen){
        selectComparison({
            page: analyticsData?.page ? analyticsData.page : '', 
            isOpen: isOpen, 
            modelName: analyticsData?.modelName,
            modelYear: analyticsData?.modelYear, trim: props?.analyticsData?.trim
        });
    }

    function sendYearAnalytics(selectedYear) {
        selectDropdown({
            page: analyticsData?.page ? analyticsData.page : '',
            label: 'Model Year Select',
            contentTitle: selectedYear,
            modelName: analyticsData?.modelName,
            modelYear: analyticsData?.modelYear,
            trim: props?.analyticsData?.trim
        })
    }


    function sendTrimAnalytics(selectedTrim) {
        selectDropdown({
            page: analyticsData?.page ? analyticsData.page : '',
            label: 'Trim Select',
            contentTitle: selectedTrim,
            modelName: analyticsData?.modelName,
            modelYear: analyticsData?.modelYear,
            trim: props?.analyticsData?.trim
        })
    }

    if (comparisonActive && selectedTrim) {
        return (
            <div className='trim-comparison-container'>
                <div className='trim-comparison-first'>
                    <div className='trim-comparison-first-image'>
                        <img 
                            src={firstSelectedImage} 
                            onError={() =>
                                setFirstSelectedImage(
                                    encodeURI(
                                    AppSettings.AWSImgRepo.resources + 'en/image_coming_soon_placeholder.png'
                                    ).toString()
                                )
                            }
                        />
                    </div>
                    <div className='trim-comparison-text'>
                        <span className='trim-comparison-year'>{year + ' '}</span>
                        <span className='trim-comparison-level'>{selectedTrim?.name}</span>
                    </div>
                </div>
                <div className='trim-comparison-divider'></div>
                <div className='trim-comparison-second'>
                    <div className='img-button-group'>
                        {isMobileView(window.innerWidth) && <CloseButton />}
                        <div className='trim-comparison-second-image'>
                            <img 
                                src={secondSelectedImage} 
                                onError={() =>
                                    setSecondSelectedImage(
                                        encodeURI(
                                        AppSettings.AWSImgRepo.resources + 'en/image_coming_soon_placeholder.png'
                                        ).toString()
                                    )
                                }
                            />
                        </div>
                        {isTabletView(window.innerWidth) && <CloseButton />}
                    </div>
                    
                    <div className='trim-comparison-dropdowns'>
                        <DropdownV2
                            style='white'
                            options={comparisonYears}
                            rootClassName='trim-comparison-dropdown-menu'
                            value={secondSelectedYear.toString()}
                            onValueChange={(selection) => {
                                setSecondSelectedYear(selection.value);
                                sendYearAnalytics(selection.value);
                            }}
                        />
                        <DropdownV2
                            style='white'
                            options={secondTrimsOptions.map(t => t.name)}
                            rootClassName='trim-comparison-dropdown-menu'
                            value={secondSelectedTrim?.name}
                            onValueChange={(selection) => {
                                const selectedData = secondTrimsOptions.find(t => t.name === selection.value)
                                setSecondSelectedTrim(selectedData);
                                sendTrimAnalytics(selectedData.name);
                            }}
                        />
                    </div>
                    {isDesktopView(window.innerWidth) && <CloseButton />}
                </div>
            </div>
        );
    }
    return (
        <div className='trim-comparison-open' onClick={() => { setComparisonActive(true); sendComparisonAnalytics(true); }}>
            <div className='trim-comparison-icon'>
                <img src={AppSettings.AWSImgRepo.resources + 'en/plus-circle-black.svg'} />
            </div>
            <div className='trim-comparison-text'>Add Second Trim to Compare</div>
        </div>
    );
};

export default TrimComparisonComponent;
