import React from "react";
import { useEffect, useState } from "react";
import '../../../styles/scss/resources-v2/trim-walk.scss';
import { VehicleChoice } from "./vehicleChoice";

export const VehicleChoiceSections = (props) => {
    const [years, setYears] = useState(null);

    const [veh1Engines, setVeh1Engines] = useState(null);
    const [veh1Trims, setVeh1Trims] = useState(null);
    const [veh1FilteredTrims, setVeh1FilteredTrims] = useState(null);

    const [veh2Engines, setVeh2Engines] = useState(null);
    const [veh2Trims, setVeh2Trims] = useState(null);
    const [veh2FilteredTrims, setVeh2FilteredTrims] = useState(null);

    const [veh3Engines, setVeh3Engines] = useState(null);
    const [veh3Trims, setVeh3Trims] = useState(null);
    const [veh3FilteredTrims, setVeh3FilteredTrims] = useState(null);

    const [selectedVeh1Year, setSelectedVeh1Year] = useState(null);
    const [selectedVeh1Engine, setSelectedVeh1Engine] = useState(null);
    const [selectedVeh1Trim, setSelectedVeh1Trim] = useState(null);

    const [selectedVeh2Year, setSelectedVeh2Year] = useState(null);
    const [selectedVeh2Engine, setSelectedVeh2Engine] = useState(null);
    const [selectedVeh2Trim, setSelectedVeh2Trim] = useState(null);

    const [selectedVeh3Year, setSelectedVeh3Year] = useState(null);
    const [selectedVeh3Engine, setSelectedVeh3Engine] = useState(null);
    const [selectedVeh3Trim, setSelectedVeh3Trim] = useState(null);

    const [updateVeh1Trims, setUpdateVeh1Trims] = useState(0);
    const [updateVeh2Trims, setUpdateVeh2Trims] = useState(0);
    const [updateVeh3Trims, setUpdateVeh3Trims] = useState(0);

    useEffect (() => {
        if(veh1Engines) {
            props.setShowThirdColumn(veh1Trims?.length > 2);
        }
    }, [veh1Trims]);

    useEffect(() => {
        if (props.year && props.years) {
            const years = props.years?.filter(y => y >= 2019)?.map(y => y.toString())
            setYears(years)

            setSelectedVeh1Year(props.year?.toString())
            setSelectedVeh2Year(props.year?.toString())
            setSelectedVeh3Year(props.year?.toString())
        }
      }, [props.year, props.years])

    useEffect(() => {
        if (veh1Engines?.includes(selectedVeh1Engine)) {
            // if new year has already selected engine, update trims data for new year/same engine
            setUpdateVeh1Trims(updateVeh1Trims + 1)
        } else {
            // select new engine
            setSelectedVeh1Engine(veh1Engines?.[0])
        }
    }, [veh1Engines])

    useEffect(() => {
        if(selectedVeh1Engine || updateVeh1Trims) {
            const modelNamesCodes = veh1Trims
                ?.filter(t => t.engine === selectedVeh1Engine)
            setVeh1FilteredTrims(modelNamesCodes)
            // if new year/engine has already selected trim, update trims data for new year/engine, same trim
            const foundModelCode = modelNamesCodes?.find(t => t.modelCode === selectedVeh1Trim?.modelCode)
            if(foundModelCode) {
                setSelectedVeh1Trim(foundModelCode)
            } else {
                setSelectedVeh1Trim(modelNamesCodes[0])
            }
        }
    }, [selectedVeh1Engine, updateVeh1Trims])

    useEffect(() => {
        // when veh1 trim changes, if second selected trim already higher msrp, keep as is
        if(veh2Engines && veh2Trims) {
            if ((selectedVeh1Trim && !selectedVeh1Trim?.msrp) || (selectedVeh2Trim && !selectedVeh2Trim?.msrp)) {
                // if MSRP TBD, filter/select default engines
                setSelectedVeh2Engine(veh2Engines?.[0])
            }
            else if (!selectedVeh2Trim || selectedVeh2Trim?.msrp <= selectedVeh1Trim?.msrp || !veh2Engines?.includes(selectedVeh2Engine)) {
                // filter veh2Trims by msrp
                const msrpFilteredTrims = veh2Trims?.filter(t => t.msrp > selectedVeh1Trim?.msrp)
                const selectedTrimFound = veh2Trims?.filter(t => t.modelCode === selectedVeh1Trim?.modelCode)
                const nextHighestTrim = msrpFilteredTrims?.[0] || selectedTrimFound?.[0] || veh2FilteredTrims?.[0]

                if (nextHighestTrim?.engine !== selectedVeh2Engine) {
                    // if next highest trim is a different engine, update engine
                    // also update to correct next highest trim
                    setSelectedVeh2Engine(nextHighestTrim?.engine)
                } 
                else if (veh2Engines?.includes(selectedVeh2Engine)) {
                    // if new year has already selected engine, update trims data for new year/same trim
                    setUpdateVeh2Trims(updateVeh2Trims + 1)
                }
                else {
                    // select new engine
                    setSelectedVeh2Engine(veh2Engines?.[0])
                }
            } else {
                // refresh trims list
                setUpdateVeh2Trims(updateVeh2Trims + 1)
            }
        }
        
    }, [veh2Engines, veh2Trims, selectedVeh1Trim])

    useEffect(() => {
        // when veh2 trim changes, if second selected trim already higher msrp, keep as is
        if(veh3Engines && veh3Trims) {
            if ((selectedVeh2Trim && !selectedVeh2Trim?.msrp) || (selectedVeh3Trim && !selectedVeh3Trim?.msrp)) {
                // if MSRP TBD, filter/select default engines
                setSelectedVeh3Engine(veh3Engines?.[0])
            }
            else if (!selectedVeh3Trim || selectedVeh3Trim?.msrp <= selectedVeh2Trim?.msrp || !veh3Engines?.includes(selectedVeh3Engine)) {
                // filter veh3Trims by msrp
                const msrpFilteredTrims = veh3Trims?.filter(t => t.msrp > selectedVeh2Trim?.msrp)
                const selectedTrimFound = veh3Trims?.filter(t => t.modelCode === selectedVeh2Trim?.modelCode)
                const nextHighestTrim = msrpFilteredTrims?.[0] || selectedTrimFound?.[0] || veh3FilteredTrims?.[0]

                if (nextHighestTrim?.engine !== selectedVeh3Engine) {
                    // if next highest trim is a different engine, update engine
                    // also update to correct next highest trim
                    setSelectedVeh3Engine(nextHighestTrim?.engine)
                } 
                else if (veh3Engines?.includes(selectedVeh3Engine)) {
                    // if new year has already selected engine, update trims data for new year/same trim
                    setUpdateVeh3Trims(updateVeh3Trims + 1)
                }
                else {
                    // select new engine
                    setSelectedVeh3Engine(veh3Engines?.[0])
                }
            } else {
                // refresh trims list
                setUpdateVeh3Trims(updateVeh3Trims + 1)
            }
        }
        
    }, [veh3Engines, veh3Trims, selectedVeh2Trim])

    useEffect(() => {
        if ((selectedVeh1Trim && !selectedVeh1Trim?.msrp) || (selectedVeh2Trim && !selectedVeh2Trim?.msrp)) {
            // if MSRP TBD, filter/select default trims
            setVeh2FilteredTrims(veh2Trims)
            setSelectedVeh2Trim(veh2Trims?.[1])
        }
        else if((selectedVeh2Engine || updateVeh2Trims) && selectedVeh1Trim) {
            const modelNamesCodes = veh2Trims
                ?.filter(t => t.msrp > selectedVeh1Trim?.msrp)
                ?.filter(t => t.engine === selectedVeh2Engine)
            const selectedEngineTrims = veh2Trims?.filter(t => t.engine === selectedVeh2Engine)
            const selectedTrimFound = selectedEngineTrims?.filter(t => t.modelCode === selectedVeh1Trim?.modelCode)
            const nextHighestTrim = modelNamesCodes?.[0] || selectedTrimFound?.[0] || selectedEngineTrims?.[selectedEngineTrims.length - 1]; // if not found in selected year, then default to highest
            const filteredTrims = modelNamesCodes?.length ? modelNamesCodes : nextHighestTrim && [nextHighestTrim]
            setVeh2FilteredTrims(filteredTrims)
            // if new year/engine has already selected trim, update trims data for new year/engine, same trim
            const foundModelCode = modelNamesCodes?.find(t => t.modelCode === selectedVeh2Trim?.modelCode)
            if(foundModelCode) {
                setSelectedVeh2Trim(foundModelCode)
            } else {
                setSelectedVeh2Trim(nextHighestTrim)
            }
            
        }
    }, [selectedVeh2Engine, updateVeh2Trims])

    useEffect(() => {
        if ((selectedVeh2Trim && !selectedVeh2Trim?.msrp) || (selectedVeh3Trim && !selectedVeh3Trim?.msrp)) {
            // if MSRP TBD, filter/select default trims
            setVeh3FilteredTrims(veh3Trims)
            setSelectedVeh3Trim(veh3Trims?.[1])
        }
        else if((selectedVeh3Engine || updateVeh3Trims) && selectedVeh2Trim) {
            const modelNamesCodes = veh3Trims
                ?.filter(t => t.msrp > selectedVeh2Trim?.msrp)
                ?.filter(t => t.engine === selectedVeh3Engine)
            const selectedEngineTrims = veh3Trims?.filter(t => t.engine === selectedVeh3Engine)
            const selectedTrimFound = selectedEngineTrims?.filter(t => t.modelCode === selectedVeh2Trim?.modelCode)
            const nextHighestTrim = modelNamesCodes?.[0] || selectedTrimFound?.[0] || selectedEngineTrims?.[selectedEngineTrims.length - 1]; // if not found in selected year, then default to highest
            const filteredTrims = modelNamesCodes?.length ? modelNamesCodes : nextHighestTrim && [nextHighestTrim]
            setVeh3FilteredTrims(filteredTrims)
            // if new year/engine has already selected trim, update trims data for new year/engine, same trim
            const foundModelCode = modelNamesCodes?.find(t => t.modelCode === selectedVeh3Trim?.modelCode)
            if(foundModelCode) {
                setSelectedVeh3Trim(foundModelCode)
            } else {
                setSelectedVeh3Trim(nextHighestTrim)
            }
            
        }
    }, [selectedVeh3Engine, updateVeh3Trims])

    useEffect(() => {
        props.updateSelectedModelCode(1, selectedVeh1Trim?.modelCode, selectedVeh1Year, selectedVeh1Trim?.msrp, selectedVeh1Trim?.name, selectedVeh1Trim?.fullName);
    }, [selectedVeh1Trim])

    useEffect(() => {
        props.updateSelectedModelCode(2, selectedVeh2Trim?.modelCode, selectedVeh2Year, selectedVeh2Trim?.msrp, selectedVeh2Trim?.name, selectedVeh2Trim?.fullName);
    }, [selectedVeh2Trim])

    useEffect(() => {
        props.updateSelectedModelCode(3, selectedVeh3Trim?.modelCode, selectedVeh3Year, selectedVeh3Trim?.msrp, selectedVeh3Trim?.name, selectedVeh3Trim?.fullName);
    }, [selectedVeh3Trim])

    return (
        <>
            <VehicleChoice
                index={0}
                modelName={props.model}
                modelYear={props.year}
                years={years}
                engines={veh1Engines}
                setEngines={setVeh1Engines}
                trims={veh1FilteredTrims}
                setTrims={setVeh1Trims}
                selectedYear={selectedVeh1Year}
                selectedEngine={selectedVeh1Engine}
                selectedTrim={selectedVeh1Trim}
                setSelectedYear={setSelectedVeh1Year}
                setSelectedEngine={setSelectedVeh1Engine}
                setSelectedTrim={setSelectedVeh1Trim}
                updateFeaturesSpecs={props.updateFeatureAndSpecsVeh1}
                updateSelectedModelCode={props.updateSelectedModelCode}
                pageData={props.pageData}
            />
            <VehicleChoice
                index={1}
                modelName={props.model}
                modelYear={props.year}
                years={years}
                engines={veh2Engines}
                setEngines={setVeh2Engines}
                trims={veh2FilteredTrims}
                setTrims={setVeh2Trims}
                selectedYear={selectedVeh2Year}
                selectedEngine={selectedVeh2Engine}
                selectedTrim={selectedVeh2Trim}
                setSelectedYear={setSelectedVeh2Year}
                setSelectedEngine={setSelectedVeh2Engine}
                setSelectedTrim={setSelectedVeh2Trim}
                minMsrp={selectedVeh1Trim?.msrp}
                updateFeaturesSpecs={props.updateFeatureAndSpecsVeh2}
                pageData={props.pageData}
            />
            {props?.showThirdColumn && <VehicleChoice
                index={2}
                modelName={props.model}
                modelYear={props.year}
                years={years}
                engines={veh3Engines}
                setEngines={setVeh3Engines}
                trims={veh3FilteredTrims}
                setTrims={setVeh3Trims}
                selectedYear={selectedVeh3Year}
                selectedEngine={selectedVeh3Engine}
                selectedTrim={selectedVeh3Trim}
                setSelectedYear={setSelectedVeh3Year}
                setSelectedEngine={setSelectedVeh3Engine}
                setSelectedTrim={setSelectedVeh3Trim}
                minMsrp={selectedVeh1Trim?.msrp}
                updateFeaturesSpecs={props.updateFeatureAndSpecsVeh3}
                pageData={props.pageData}
            />}
        </>
    )
};