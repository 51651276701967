import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import DropdownV2 from '../../../components/molecules/dropdownV2';
import Pagination from "../../../components/molecules/engage-xp-pagination/Pagination";
import usePagination from "../../../components/molecules/engage-xp-pagination/usePagination";
import { getNitroBadges } from "../../../services/nitroService";
import "../../../styles/css/engageXP/badges.css";
import { badgesDropdownClick } from "../engageXPStats.analytics";

const Badges = ({ missions, pageData, analyticsData }) => {
  const MAX_ITEMS_BY_DEVICE = {
    desktop: 9,
    tablet: 8,
    mobile: 8
  };

  const [device, setDevice] = useState('desktop');
  const [badges, setBadges] = useState([]);
  const [filteredBadges, setFilteredBadges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBadgeFilter, setSelectedBadgeFilter] = useState('All');

  const getFilteredBadges = () => {
    return badges.filter((badge) => {
      if (selectedBadgeFilter === "All") {
        return true;
      }
      return (
        badge.earnedFrom.missionLabels &&
        badge.earnedFrom.missionLabels.some((label) => label.name === selectedBadgeFilter)
      );
    });
  };

  useEffect(() => {
    const filteredBadgesResult = getFilteredBadges();
    setFilteredBadges(filteredBadgesResult);
  }, [badges, selectedBadgeFilter]);

  const {
    displayedItems,
    page,
    setDisplayedItems,
    setPage,
    handlePaginationBack,
    handlePaginationNext,
  } = usePagination({ items: filteredBadges, pageSize: MAX_ITEMS_BY_DEVICE[device] });

  const getBadges = async () => {
    setIsLoading(true);

    const device = getDevice();
    const {response: items} = await getNitroBadges();

    setPage(1);
    setDevice(device);
    setBadges(items);

    setIsLoading(false);
    handleSetDisplayedItems(1, MAX_ITEMS_BY_DEVICE[device], items);
  };

  const handleSetDisplayedItems = (page, maxItems, items) => {
    setDisplayedItems(items.slice((page - 1) * maxItems, page * maxItems));
  };

  const getBadgeDate = (date) => {
    const badgeDate = new Date(date * 1000); 
    return `${badgeDate.toLocaleString('default', { month: 'short' })} ${badgeDate.getDate()}, ${badgeDate.getFullYear()}`;
  };

  const getDevice = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1595) {
      return 'desktop';
    }
    if (screenWidth > 950) {
      return 'tablet';
    }
    return 'mobile';
  };

  const onDeviceUpdate = () => {
    setDevice(getDevice())
  };

  useEffect(() => {
    handleSetDisplayedItems(page, MAX_ITEMS_BY_DEVICE[device], filteredBadges);
  }, [page, filteredBadges]);

  useEffect(() => {
    getBadges();
  }, [device]);

  useEffect(() => {
    getBadges();
    
    window.addEventListener("resize", onDeviceUpdate);
    return () => window.removeEventListener("resize", onDeviceUpdate);
  }, []);

  useEffect(() => {
    for (const badge of badges) {
      if (badge.earnedFrom.type === "CHALLENGE") {
        const challengeId = badge.earnedFrom.challengeId;
        const matchingMission = missions.find(mission => mission.id === challengeId);
        if (matchingMission) {
          badge.earnedFrom.missionLabels = matchingMission.labels;
        }
      }
    }
  
    handleSetDisplayedItems(page, MAX_ITEMS_BY_DEVICE[device], filteredBadges);
  }, [page, badges, missions]);

  const extractUniqueMissionLabels = (badges) => {
    const uniqueLabels = new Set();
    badges.forEach((badge) => {
      if (badge.earnedFrom.missionLabels) {
        badge.earnedFrom.missionLabels.forEach((label) => {
          uniqueLabels.add(label.name);
        });
      }
    });
    const labelArray = ['All', ...Array.from(uniqueLabels)];
    return labelArray;
  };

  const badgeFilterOptions = extractUniqueMissionLabels(badges);

  const handleFilterChange = (selectedCategory) => {
    setSelectedBadgeFilter(selectedCategory.value);
    const dropdownValue = selectedCategory.value;
    if (analyticsData){
      const clickAnalytics = analyticsData?.clickAnalytics;
      badgesDropdownClick(clickAnalytics, dropdownValue);
    }
  };
  
  return <div className="badges-container">
    <div>
      <div className="header">
        <span className="header-title">{pageData?.stats.badgeTitle ?? ''}</span>
        <div className="header-dropdown">
          <DropdownV2
            style="white"
            options={badgeFilterOptions}
            value={selectedBadgeFilter}
            onValueChange={handleFilterChange}
          />
        </div>
      </div>

      {isLoading && <ul className="list">
        {Array.from({ length: MAX_ITEMS_BY_DEVICE[device] })?.map((_, i) => i).map(i => <Skeleton key={i} height={200} className="badge-item-loading" />)}
      </ul>}

      {!isLoading && <>
        {!displayedItems?.length && <span className="empty-text">No badges to show.</span>}

        {!!displayedItems?.length && <ul className="list">
          {displayedItems?.map(badge => (
            <li key={badge?.title}>
              <img src={badge?.thumbUrl} />
              <h6>{badge?.title}</h6>
              <span>Earned On</span>
              <span>{getBadgeDate(badge?.lastEarnedTime)}</span>
            </li>
          ))}
        </ul>}
      </>}
    </div>
    {!!displayedItems?.length && <Pagination 
      items={filteredBadges}
      page={page}
      pageName="badges"
      pageSize={MAX_ITEMS_BY_DEVICE[device]}
      handlePaginationBack={handlePaginationBack}
      handlePaginationNext={handlePaginationNext}
    />}
  </div>
}

export default Badges;
