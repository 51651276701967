import React from "react";
import ProfileField from './profileField';

const ProfileSection = ({ fields, onClick }) => {

  return (
    <div className='profile-info-column'>
      {fields.map((field) => (
        <ProfileField
          label={field?.label}
          value={field.value}

          // Pass onClick only if it's a link and it's the "SPIN ID" field
          onClick={field.label?.toLowerCase() === 'spin id' ? 
            () => {onClick();} 
          : undefined}
        />
      ))}
    </div>
  );
};

export default ProfileSection;
